import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreatereminderComponent } from './createreminder/createreminder.component';
import { ViewreminderComponent } from './viewreminder/viewreminder.component';
import { ReminderhistoryComponent } from './reminderhistory/reminderhistory.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { AsapMaterialModule } from '../../../../material-module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRippleModule} from '@angular/material/core';
import {MatSortModule} from '@angular/material/sort';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {FuseWidgetModule} from '../../../../../@fuse/components';
import {AgmCoreModule} from '@agm/core';
import {MatSelectModule} from '@angular/material/select';
import {BaseListService} from '../../base/base-list.service';
import {StatusService} from '../status/status.service';
import {MatChipsModule} from '@angular/material/chips';
import {SharedModule} from '../../common/shared.module';
import {AuthGuard} from '../../auth/auth.guard';
import { ReminderdetailComponent } from './reminderdetail/reminderdetail.component';
import { EditReminderDialougeComponent } from './edit-reminder-dialouge/edit-reminder-dialouge.component';



const routes = [
    {
        path     : 'reminders/createreminder',
        component: CreatereminderComponent,
        canActivate: [AuthGuard],
    },
    {
        path     : 'reminders/viewreminder',
        component: ViewreminderComponent,
        canActivate: [AuthGuard],
    },
    {
        path     : 'reminders/reminderhistory',
        component: ReminderhistoryComponent,
        canActivate: [AuthGuard],
    },

    {
        path     : 'remindersDetail',
        component: ReminderdetailComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        CreatereminderComponent,
        ViewreminderComponent,
        ReminderhistoryComponent,
        ReminderdetailComponent,
        EditReminderDialougeComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        AsapMaterialModule,
        NgxDaterangepickerMd,
        SharedModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,

        NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule

    ],
    exports     : [
      CreatereminderComponent,
      ViewreminderComponent,
      ReminderhistoryComponent
    ],
    providers   : [
        StatusService,
       
]
})
export class RemindersModule { }
