import { Injectable } from '@angular/core';
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { StorageService } from "app/main/module/common/service/storage.service";
import { FirebaseUtilService } from "../../../../../main/module/common/service/FirebaseUtil.service";
import { AngularFirestoreCollection } from "angularfire2/firestore";
import { T } from "@angular/cdk/keycodes";

// Options to reproduce firestore queries consistently
interface QueryConfig {
  path: string; // path to collection
  field: string; // field to orderBy
  limit?: number; // limit per query
  reverse?: boolean; // reverse order?
  prepend?: boolean; // prepend to source?
}
@Injectable({
  providedIn: 'root'
})
export class OthermachineInfiniteScrollService {
  public itemsObservable: Observable<any[]>;
  public pageSubject: BehaviorSubject<number>;
  public pageObservable: Observable<number>;
  public subscription: any;
  public notFoundObservable: Observable<boolean>;
  public notFoundSubject: BehaviorSubject<boolean>;

  // Source data
  private _done: BehaviorSubject<boolean>;
  private _loading: BehaviorSubject<boolean>;
  private _data: BehaviorSubject<any[]>;

  private query: QueryConfig;

  // Observable data
  data: Observable<any>;
  done: Observable<boolean>;
  loading: Observable<boolean>;

  protected table = "maintanance";
  protected orderBy = "updatedOn";
  protected isOrderByNegative = true;
  public searchedText: string = null;
  searchPatient: any;

  constructor(
    private afs: AngularFirestore,
    public firebaseUtil: FirebaseUtilService,
    public storageServise: StorageService,
    private userService: StorageService
  ) {
    this.table = "maintanance";
    this.orderBy = "updatedOn";
  }

  getEmailDetailsById(id) {
    return new Promise((resolve, reject) => {
      this.afs
        .collection(this.table)
        .doc(id)
        .valueChanges()
        .subscribe((data) => {
          resolve(data);
        });
    });
  }

  setPositiveOrderBy(): void {
    this.isOrderByNegative = false;
  }
  getCollectionPath(): string {
    // return "lab/" + this.userService.getCookie("lab") + "/" + this.table +"/result/load";
    return "lab/" + this.userService.getCookie("lab") + "/" + this.table + "/result/history";
  }

  // Initial query sets options and defines the Observable
  init(path, limit, field, opts?, all_records?) {
    path = this.getCollectionPath();
    console.log("path " + path);
    this.query = {
      path,
      field,
      limit: limit,
      reverse: false,
      prepend: false,
      ...opts,
    };
    // console.log("query " + JSON.stringify(this.query));
    this._done = new BehaviorSubject(false);
    this._loading = new BehaviorSubject(false);
    this._data = new BehaviorSubject([]);
    this.data = this._data.asObservable();
    this.done = this._done.asObservable();
    this.loading = this._loading.asObservable();
    const first = this.afs.collection(this.query.path, (ref) => {
      if (all_records == true) {
      // if (true) {
        return (
          ref
              // .where("type", "!=", "Waterline") <>
            .orderBy(this.orderBy)
        );
      } else {
        console.log(this.storageServise.getCookie("lab"));
        return (
          ref
            //   .where("type", 'not-in', "Waterline")
            // .where('type', '==', 'Waterline')
            .orderBy(this.orderBy, 'desc')
            // .where('CellType', '==', 'machine')
            .limit(this.query.limit)
        );
      }
    });

    this.subscription = this.mapAndUpdate(first);

    this._data.next([]);
    // Create the observable array for consumption in components
    this.data = this._data.asObservable().scan((acc, val) => {
      return this.query.prepend ? val.concat(acc) : acc.concat(val);
    });
  }

  initWithPrev(oldCursor, oldData, path, limit, field, opts?, all_records?) {
    this.oldCursor = oldCursor;
    this.oldData = oldData;
    path = this.getCollectionPath();
    console.log("path " + path);
    this.query = {
      path,
      field,
      limit: limit,
      reverse: false,
      prepend: false,
      ...opts,
    };
    console.log("query " + JSON.stringify(this.query));
    this._done = new BehaviorSubject(false);
    this._loading = new BehaviorSubject(false);
    this._data = new BehaviorSubject([]);
    this.done = this._done.asObservable();
    this.loading = this._loading.asObservable();

    this._loading.next(false);
    this._data.next([]);
    // this._data.next(oldData)

    // Create the observable array for consumption in components
    this.data = this._data.asObservable().scan((acc, val) => {
      return this.getRequiredArray(val, acc);
    });
  }

  getRequiredArray(val: ConcatArray<any>[], acc: ConcatArray<any>[]) {
    console.log("val" + val + "acc" + acc);
    if (val && acc) {
      return acc.concat(val);
    } else if (val) {
      return val;
    } else if (acc) {
      return acc;
    } else return [];
  }

  // Retrieves additional data from firestore
  more() {
    const cursor = this.getCursor();
    // console.log('cursor  >> '+cursor.id)
    // console.log('cursor  >> '+cursor.size)

    const more = this.afs.collection(this.query.path, (ref) => {
      return (
        ref
          .orderBy(this.orderBy, 'desc')
          //  .where("type", "!==", "Waterline")
          .limit(this.query.limit)
          .startAfter(cursor)
      );
    });
    console.log('>>>>>>>> '+more)
    this.mapAndUpdate(more);
  }

  oldData: Array<any>;
  oldCursor: any = null;
  // Determines the doc snapshot to paginate query
  public getCursor() {
    // console.log('this._data.value.length ='+this._data.value.length)
    if (this._data && this._data.value && this._data.value.length) {
    } else if (this.oldCursor) {
      console.log(this.oldCursor);
      return this.oldCursor;
    }

    const current = this._data.value;
    if (current.length) {
      return this.query.prepend
        ? current[0].doc
        : current[current.length - 1].doc;
    }
    return null;
  }

  // Maps the snapshot to usable format the updates source
  private mapAndUpdate(col: AngularFirestoreCollection<any>) {
    if (this._done.value || this._loading.value) {
      return;
    }

    // loading
    this._loading.next(true);

    // Map snapshot with doc ref (needed for cursor)

    return col
      .snapshotChanges()
      .do((arr) => {
        //    for(var i =0; i< arr.length; i++){
        //       const vv
        //    }
        let values = arr.map((snap) => {
          const data = snap.payload.doc.data();
          console.log(data.type.toString().includes("Waterline" || "waterline"),data.CellType)
          // if (!data.type.toString().includes("Waterline" || "waterline")) {
          if (data.type!="Waterline" && data.CellType!="machine") {
            // if (!data.type.toString().includes("Waterline"|| "waterline") && data.CellType!=("machine")) {

            // if(true){
            // if (data.CellType == "machine") {
            //   console.log("CellType = "+data.CellType)
            // }else{
            //   const doc = snap.payload.doc;
            //   return { ...data, doc };
            // }
            const doc = snap.payload.doc;
            return { ...data, doc };
          }
          // }    
        });
        // If prepending, reverse array

        var values2: Array<any> = [];
        for (var i = 0; i < values.length; i++) {

          if (values[i] !== undefined) {
            values2.push(values[i])
          }
        }

        console.log(values2)
        values2 = this.query.prepend ? values2.reverse() : values2;
        // update source with new values, done loading// searchPatient
        console.log('>> searchPatient =  ' + localStorage.getItem('searchPatient'))
        this.searchPatient = JSON.parse(localStorage.getItem('searchPatient'));

        if (this.searchPatient) {

          if ((values2[0].id).includes(this.searchPatient)) {
            console.log('SearchData=', this.searchPatient)
            this._data.next([]);
          }
          else {

            this._data.next(values2)
          }
        } else {

          this._data.next(values2)
        }
        // update source with new values, done loading
        this._loading.next(false)
        if (!values2.length) {
          this._done.next(true)
        }
      })
      //     this._data.next(values);
      //     this._loading.next(false);

      //     // no more values, mark done
      //     if (!values.length) {
      //         this._done.next(true);
      //     }
      // })
      .take(2)
      .subscribe();
  }

  // Reset the page
  reset() {
    this._data.next([]);

    this._done.next(false);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
