import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {StatusService} from '../../status/status.service';
import {MatTableDataSource} from '@angular/material/table';
import {Observable} from 'rxjs';
import { LoadService } from '../../load/load.service';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {ApiService} from '../../../common/service/api.service';
import { ReminderslistService } from '../reminderslist.service';
import { ViewreminderserviceService } from '../viewreminder/viewreminderservice.service';
import { StorageService } from 'app/main/module/common/service/storage.service';


@Component({
  selector: 'app-reminderdetail',
  templateUrl: './reminderdetail.component.html',
  styleUrls: ['./reminderdetail.component.scss'],
  animations: fuseAnimations
})
export class ReminderdetailComponent implements  OnDestroy,OnInit, AfterViewInit {
  urlData = '';
  remindersObservableData: Observable<any>;
  cstObservableData: Observable<any>;
  maintenaceDetailsData: any;
  remindersId: any;
  taskName: any;
  taskDesc: any;
  taskType: any;
  scheduleOn: any;
  no_Of_days: any;
  scheduletype: any;
  nextDueDate: any;
  createdBy: any;
  createdOn: any;


  constructor( public reminderlistService: ReminderslistService,private paginationService: ViewreminderserviceService,
    private route: ActivatedRoute, private router: Router,private storageServise:StorageService,
    private location: Location, public dialog: MatDialog, public apiService: ApiService,) { }

  ngOnDestroy(): void {
     this.remindersObservableData
}

ngOnInit(): void {
    this.urlData = this.route.snapshot.queryParams.reminder_id;
    console.log("urldata=",this.urlData)
}


ngAfterViewInit(): void {

    this.remindersObservableData = this.reminderlistService.getreminderHistoryById(this.urlData);
    this.remindersObservableData.subscribe(resultData => {
      if(resultData[0] != undefined){
      console.log("data",resultData[0])
      this.remindersId =resultData[0].reminder_id;
      this.maintenaceDetailsData = resultData[0]
      console.log(this.maintenaceDetailsData.task_name)
      this.taskName = resultData[0].task_name
      this.taskDesc = resultData[0].task_desc
      this.taskType =resultData[0].reminder_type
      this.scheduleOn =resultData[0].schedule_date
      this.scheduletype=resultData[0].schedule_type
      console.log('n0-',resultData[0].no_of_days)
      if(resultData[0].no_of_days != ' '){
      this.no_Of_days =resultData[0].no_of_days
      }
      else{
        this.no_Of_days ='-'
      }
      this.nextDueDate = this.apiService.getDateStringMomentByTzOnlyDate(resultData[0].next_due_date)
      this.createdBy =resultData[0].created_by
      this.createdOn =this.apiService.getDateStringMomentByTz(resultData[0].created_on)
      }
      else{

        this.paginationService.getremindersDataById(this.urlData).subscribe(resultData => {

          console.log("data",resultData[0])
          this.remindersId =resultData[0].reminder_id;
          this.maintenaceDetailsData = resultData[0]
          console.log(this.maintenaceDetailsData.task_name)
          this.taskName = resultData[0].task_name
          this.taskDesc = resultData[0].task_desc
          this.taskType =resultData[0].reminder_type
          this.scheduleOn =resultData[0].schedule_date
          this.scheduletype=resultData[0].schedule_type
          if(resultData[0].no_of_days != ' '){
            this.no_Of_days =resultData[0].no_of_days
            }
            else{
              this.no_Of_days ='-'
            }
          this.nextDueDate = this.apiService.getDateStringMomentByTzOnlyDate(resultData[0].next_due_date)
          this.createdBy =resultData[0].created_by
          this.createdOn =this.apiService.getDateStringMomentByTz(resultData[0].created_on)

        })

      }
      

    })

}

// back(): void {
//   this.location.back()
// }

back() {
  // this.router.navigate(["/maintenanceHistory"])
  this.location.back()
//   this.router.navigate(["/maintenanceHistory"]).then(() => {
// //  this.ngOnDestroy();
// this.ngOnInit()
// //  window.location.reload();
// });
}

openDetail(Url) {
  window.open(Url, "_blank");
}

}

