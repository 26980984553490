import { ChangeDetectionStrategy, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ViewreminderserviceService } from './viewreminderservice.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Reminders } from '../reminder';
import * as moment from 'moment';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ReminderslistService } from '../reminderslist.service';
import { AngularFirestore } from '@angular/fire/firestore'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'app/main/module/common/service/api.service';
@Component({
  selector: 'app-viewreminder',
  templateUrl: './viewreminder.component.html',
  styleUrls: ['./viewreminder.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: fuseAnimations
})
export class ViewreminderComponent implements OnInit {
  // dataSubscription: any;
  data=[];
  columns = this.getColumn();
  // titleMatIcon = this.getTitleMatIcon();
  // title = this.getTitle();
  displayedColumns = this.columns
      .filter((c) => c.hide === false)
      .map((c) => c.columnDef);
  dataSource = new MatTableDataSource<any>(this.data);
  @ViewChild("filter", { static: true })
  filter: ElementRef;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  hasFirstDataShown: boolean;
  page: number;
  private remindersSubscription: Subscription;
  delReminderId: any;
  taskFormEdit: FormGroup;
  hasEditDataShown: boolean;
  beforeEdit: any;
  editReminderId: any;
  updatedData:any;
  private interval: any;
  noOfdays: string;
  finalNextDate: string;
  nextDueDate: number;
  sheduledateHour: string;
  nextDueDateTime: number;
  minDateTime: Date;
  taskName: any;
  constructor(private paginationService: ViewreminderserviceService,private userSevice1Child:StorageService,
    private router: Router,public dialog: MatDialog,private reminderListservice:ReminderslistService,private apiService:ApiService,
    private firestore: AngularFirestore,private fb: FormBuilder,) {

      this.taskFormEdit = this.fb.group({
        taskName: ['', Validators.required],
        taskDescription: [''],
        type: ['', Validators.required],
        dateControl: [null, Validators.required],
        time: [" "],
        days: [" "],
      });

      this.interval = setInterval(() => this.checkReminders(), 20000);
     }

   ngOnInit(): void {
   this.minDateTime = new Date();
    this.interval = setInterval(() => this.checkReminders(), 20000);
  this.dataSource.data = [];
 this.data =[]
  this.hasFirstDataShown = false;
  this.page = 0;
 this.getData();

  // this.initData();
 
}

ngAfterViewInit() {
  setTimeout(() => {
    // Code causing changes
    3000
  });
}


checkReminders() {
  this.paginationService.getAllReminderFromHistory().subscribe(reminders => {
    reminders.forEach(reminder => {
      const data = reminder.data() as any;
      const date = new Date();
      const formattedDate = this.formatTimestamp(date.getTime());
      const nextDueDateOnly = this.formatTimestamp(data.next_due_date);
      if (data.schedule_date == formattedDate && data.platform == "Web" && data.reminder_type =="Once") {
        this.moveDocument(data.id);
      }
      else if((data.schedule_date == formattedDate && data.platform == "Web" && data.reminder_type =="Repeat")){
        const newData = {
         updated_on: new Date().getTime(),
         updated_by: this.userSevice1Child.getCookie('name'), 
         status: 'Pending',
       }
       
      const mess   = this.reminderListservice.moveDocumentandUpdate(data.id,newData);
      // console.log("Message=", mess);
      const formattedDateRepeat = this.formatTimestamp(data.next_due_date);
      console.log("Next date=",formattedDateRepeat)

        if(mess == "Success" && formattedDateRepeat == formattedDate){
        // const formattedDateRepeat = this.formatTimestamp(data.next_due_date);
        // console.log("ss",formattedDateRepeat)
        data.next_due_date = data.next_due_date - (24 * 60 * 60 * 1000)
        const momentObject = moment(data.next_due_date);
        const newMomentObject = momentObject.add(data.no_of_days, 'days');
        const newTimestamp = newMomentObject.valueOf();
        console.log("repeat nextduedate=",formattedDateRepeat,newTimestamp)
       const nextsheduleData = {
        next_due_date:newTimestamp,
        schedule_date:formattedDateRepeat
       }
          this.reminderListservice.updateReminderViewbyDocId(data.id,nextsheduleData);
        }    
        
      }

    });
  });
}


formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}




getData(){
  this.dataSource.data = [];
  this.data =[]
  this.paginationService.getAllData().subscribe((value) => {
    
    value.forEach(element => {             
if(element.platform == 'Web' ){

        this.data.push({
          task_name: element.task_name,
          created_by: element.created_by,
          created_on: element.created_on,
          id: element.reminder_id,
          platform: element.platform,
          next_due_date: element.next_due_date,
          no_of_days: element.no_of_days,
          reminder_id: element.reminder_id,
          reminder_type: element.reminder_type,
          schedule_date: element.schedule_date,
          schedule_epoch_timestamp: element.schedule_epoch_timestamp,
          schedule_time: element.schedule_time,
          schedule_type: element.schedule_type,
          task_desc: element.task_desc,
          updated_by: element.updated_by,
          updated_on: element.updated_on,
          status: ''
        })

        this.hasFirstDataShown = true;
    }  

    });
  
    // console.log(this.data);
    this.dataSource.data = this.data
  });

}



getColumn(): any[] {

  return [
      {
          columnDef: "Sr",
          header: "Sr.",
          hide: false,
          cell: (element: Reminders, index: number) =>
              `${this.page * 10 + index + 1}`,
      },
      {
          columnDef: "Name",
          header: "Task Name",
          hide: false,
          cell: (element: Reminders, index: number) =>
          `${element.task_name}`,
          //   this.replaceWithEmptyCellName(element)
      },
      {
          columnDef: "Type",
          header: "Type",
          hide: false,
          cell: (element: Reminders, index: number) =>
          `${element.reminder_type}`,
          // this.replaceWithEmpty(element.type)

      },
    
    //   {
    //       columnDef: "CycleNo",
    //       header: "CycleNo",
    //       hide: false,
    //       cell: (element: Maintenance, index: number) => 
    //     //   `${element.cycleNo}`,
    //     this.replaceWithEmpty(element.cycleNo)
    //   },

      {
        columnDef: "MaintenanceNo",
        header: "Sheduled On",
        hide: false,
        cell: (element: Reminders, index: number) => 
        `${element.schedule_date}`,
      //   this.replaceWithEmptycreted(element)
    },

    {
      columnDef: "CreatedBy",
      header: "Next Due Date",
      hide: false,
      cell: (element: Reminders, index: number) =>
      //  `${element.next_due_date}`,
      this.getDateStringMomentByTzonlydate(element.next_due_date),
  //   this.replaceWithCreatedBy(element.createdBy)
  },

  {
    columnDef: "MaintainedOn",
    header: "Created On",
    hide: false,
    cell: (element: Reminders, index: number) => 
    // `${element.created_on}`,
    this.getDateStringMomentByTz(element.created_on),
  //   this.getDateStringMomentByTz(element.updatedOn)
},

{
  columnDef: "Isedit",
  header: "Edit",
  hide: false,
  cell: (element: Reminders, index: number) => 
  `${element.reminder_id}`,
//   this.replaceWithEmpty(element.updatedBy)
},
{
columnDef: "deletereminder",
header: "Delete",
hide: false,
cell: (element: Reminders, index: number) => 
`${element.reminder_id}`,
//    this.replaceWithEmpty(element.aiResult)
},

{
  columnDef: "reminderdetail",
  header: "More Detail",
  hide: false,
  cell: (element: Reminders, index: number) => 
  `${element.reminder_id}`,
  //    this.replaceWithEmpty(element.aiResult)
  },
      
  ];
}


getRowBackgroundColor(element): string {
  // if (element.deleted == true) {
  //     return "lightgrey";
  // } else
  return "#FFFFFF";
}

getRowFontColor(element) {
  if (element.deleted == true) {
      return "italic";
  } else return "bold";
}

getFontColor(element) {
  if (element.deleted == true) {
      return "#808080";
  } else return "#000000";
}
getCrossWord(element) {
  if (element.deleted == true) {
      return "line-through";
  } else return "overline";
}

getDateStringMomentByTz(timestamp: any): string {
  
  if(timestamp){
      if (timestamp < 0)
          timestamp = timestamp * -1;
      const localTimeZone =  moment(timestamp).tz(this.userSevice1Child.getCookie('defaultTz'));
      return localTimeZone.format('DD-MM-YYYY | HH:mm');
  }

}

getDateStringMomentByTzonlydate(timestamp: any): string {
  if (timestamp < 0)
  timestamp = timestamp * -1;
const localTimeZone =  moment(timestamp).tz(this.userSevice1Child.getCookie('defaultTz'));
return localTimeZone.format('DD-MM-YYYY');
  // if(timestamp){
  //     if (timestamp < 0)
  //         timestamp = timestamp * -1;
  //     const localTimeZone =  moment(timestamp).tz(this.userSevice1Child.getCookie('defaultTz'));
  //     return localTimeZone.format('DD-MM-YYYY | HH:mm');
  // }

}

moveDocument(docId) {
 console.log("reminderid=",docId)
 const newData = {
  updated_on: new Date().getTime(),
  updated_by: this.userSevice1Child.getCookie('name'), 
  status: 'Pending',
};
  // Call the moveDocument function
  this.reminderListservice.moveDocument(docId,newData);
}

      openDialogWithRefedit(ref: TemplateRef<any>, reminder_id) {
        this.hasEditDataShown = false;
        this.editReminderId = ''  
        this.paginationService.getReminderById(reminder_id.trim()).then((data: any) => {
            this.beforeEdit = data;
            // Split the date string into day, month, and year.
      const parts = data.schedule_date.split('-');
      if (parts.length === 3) {
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Subtract 1 because months are zero-based.
        const year =  parseInt(parts[2], 10); // Assuming 'yy' is 2-digit year representation.

        // Create a Date object.
        const scheduleDate = new Date(year, month, day);
        this.editReminderId = data.id;
        // console.log("schdule date=", scheduleDate)
        this.taskFormEdit.setValue({
          taskName: data.task_name,
          taskDescription: data.task_desc,
          type: data.reminder_type,
          dateControl: scheduleDate, // Use the parsed Date object.
          time: data.schedule_time,
          days: data.no_of_days,
        });
      }
      this.hasEditDataShown = true;
  }).catch(error => {
      // this.handleError(error);
console.log(error)
  });

  this.dialog.open(ref, { width: '400px',height:'450px', disableClose: true });
}

editReminderById(reminderId){
  this.data = [];
  this.dataSource.data = [];
  console.log("calling edit function",reminderId,this.taskFormEdit.value)
  if (this.taskFormEdit.valid) { 
    if(this.taskFormEdit.value.type == "Once"){
      this.noOfdays = " " 
      const dateMoment = moment(this.taskFormEdit.value.dateControl);
      console.log("date time hour=", dateMoment)
      this.sheduledateHour = dateMoment.format('DD-MM-YYYY');
      const timestamp = dateMoment.valueOf();
      console.log(timestamp);
      const momentObject = moment(timestamp);
      const newMomentObject = momentObject.add(1, 'days');
      const newTimestamp = newMomentObject.valueOf();
      console.log(newTimestamp);
      this.nextDueDateTime = newTimestamp;
      console.log("Shedule Date",this.sheduledateHour,this.nextDueDateTime,timestamp); 
      }

      else{
        console.log("else calling",this.taskFormEdit.value.type); 
        const dateMoment = moment(this.taskFormEdit.value.dateControl);
        console.log("date time hour=", dateMoment);
        this.noOfdays = this.taskFormEdit.value.days;
        this.sheduledateHour = dateMoment.format('DD-MM-YYYY');
        const afteraddDays = dateMoment.add(this.taskFormEdit.value.days, 'days'); 
        console.log("after add entered days",afteraddDays);
        const timestamp = afteraddDays.unix()*1000;
        console.log(timestamp);
        const momentObject = moment(timestamp);
        const newMomentObject = momentObject.add(1, 'days');
        const newTimestamp = newMomentObject.valueOf();
        console.log(newTimestamp);
        this.nextDueDateTime = newTimestamp;
      
      } 
    // const dateMoment = moment(this.taskFormEdit.value.dateControl);
    // const dateHourMoment = moment(this.taskFormEdit.value.dateControl);
    // console.log("xcgfxg",dateHourMoment)
    // const formattedDate = dateMoment.format('DD-MM-YYYY');
    // this.finalNextDate = dateHourMoment.format('DD-MM-YYYY : hh:mm');
    // this.nextDueDate = dateHourMoment.unix();
    // console.log("timestamp nextdue date=",this.nextDueDate)
    // const timestamp = dateMoment.unix();
    // console.log("timestamp",timestamp)
    // const afterenteredDays = dateMoment.add(this.taskFormEdit.value.days, 'days'); 
    // const nextDate = afterenteredDays.unix();
    // const finalNextDate = (nextDate * 1000);
    // console.log('Next due date after update ',  nextDate,finalNextDate);
    const time = new Date().getTime();
    // if(this.taskFormEdit.value.type == "Once"){
    //   this.noOfdays = " "
    // }else{
    //   this.noOfdays = this.taskFormEdit.value.days;
    // }
    this.updatedData = {   
      next_due_date: this.nextDueDateTime ,
      no_of_days:this.noOfdays,
      reminder_type:this.taskFormEdit.value.type  ,
      schedule_date:this.sheduledateHour,
      schedule_epoch_timestamp: this.nextDueDateTime,
      schedule_time: this.taskFormEdit.value.time,
      task_desc:this.taskFormEdit.value.taskDescription,
      task_name: this.taskFormEdit.value.taskName,
      updated_by: this.userSevice1Child.getCookie('name'),
      updated_on:time,
    }
  
     this.paginationService.updateDocumentById(reminderId,this.updatedData)
     .then(() => {
       console.log('Data updated successfully');
       this.taskFormEdit.reset();
      this.ngOnInit()
     })
     .catch((error) => {
       console.error('Error adding data:', error);
     });

  }
}

viewReminderDetail(reminder_id) {
  this.router.navigate(['/remindersDetail'], { queryParams: { reminder_id: reminder_id } });
}

openDialogWithRefDel(ref: TemplateRef<any>, reminder_id,task_name): void {
  this.delReminderId = reminder_id
  this.taskName = task_name
  this.dialog.open(ref, { width: '410px' });
}

deleteReminderbyDocId(remId){
  this.data=[]
  this.dataSource.data=[];
  this.paginationService.deleteDocumentById(remId)
  .then(() => {
    console.log('Document successfully deleted.');
    this.ngOnInit();
  })
  .catch((error) => {
    console.error('Error deleting document:', error);
  });

}

}
