import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {debounceTime, finalize, map, startWith, switchMap, tap} from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {Observable, Subject} from 'rxjs';

import {fuseAnimations} from '@fuse/animations';
import {MatTableDataSource} from '@angular/material/table';
import {StorageService} from '../../../common/service/storage.service';
import {FormBuilder, FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UnassignService} from './unassign.service';
import {HttpClient} from '@angular/common/http';
import {AngularFirestore} from '@angular/fire/firestore';
import {PatientService} from '../../patient/patient.service';
import {Patient} from '../../patient/patient';
import {Cassette} from '../cassette';
import {StatusService} from '../../status/status.service';
import * as moment from 'moment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DialogUtil} from '../../../../util/DialogUtil';
import {MatDialog} from '@angular/material/dialog';
import * as copy from 'copy-to-clipboard';
import {BaseComponent} from '../../../base/base.component';
import {ConfigService} from '../../../common/config/config.service';
import {Globals} from '../../../common/global';
import {ApiService} from '../../../common/service/api.service';
import {CovidSetting} from '../../../screening/setting/screening-email-setting/ScreeningEmailSetting.model';
import {FirebaseUtilService} from '../../../common/service/FirebaseUtil.service';
import {TextUtils} from '../../../../util/TextUtils';
import {CookieService} from 'ngx-cookie-service';
const timeZone = require('moment-timezone');

export interface State {
    flag: string;
    name: string;
    population: string;
}

@Component({
    selector: 'unassign-table',
    templateUrl: './unassign.component.html',
    styleUrls: ['./unassign.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class UnassignComponent extends BaseComponent implements OnInit, OnDestroy {
    ELEMENT_DATA: Cassette[] = [];
    selectedPatient: string;
    dataSource = new MatTableDataSource<Cassette>();
    selection = new SelectionModel<Cassette>(true, []);

    stateCtrl = new FormControl();
    urlData = '';
    HASH = '#';
    enableAssign = false;
    columns = [
        { columnDef: 'Sr', header: 'Sr.', cell: (element: Cassette, index: number) => `${this.page * 10 + index + 1}` },
        { columnDef: 'Barcode', header: 'Barcode', cell: (element: Cassette, index: number) => `${element.barcode}` },
        { columnDef: 'Type', header: 'Type', cell: (element: Cassette, index: number) => `${element.type}` },
        { columnDef: 'Load No', header: 'Load No', cell: (element: Cassette, index: number) => `${element.loadNo}` },
        { columnDef: 'Cycle No', header: 'Cycle No', cell: (element: Cassette, index: number) => `${element.cycleNo}` },
        {
            columnDef: 'Result', header: 'Result',
            cell: (element: Cassette, index: number) => `${this.getCassetteResult(element)}`,
            textColor: (element: Cassette, index: number) => `${this.getLoadColor(element)}`
        },
        { columnDef: 'Date', header: 'Date', cell: (element: Cassette, index: number) => `${element.date}` },
        { columnDef: 'Time', header: 'Time', cell: (element: Cassette, index: number) => `${element.time}` },

        {
            columnDef: 'BD', header: 'BD',
            cell: (element: Cassette, index: number) => `${element.barcodeBd ? element.barcodeBd : ''}`,
            textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.statusBd)}`
        },
        {
            columnDef: 'BI', header: 'BI(Spore Test)',
            cell: (element: Cassette, index: number) => `${element.barcodeBi ? element.barcodeBi : ''}`,
            textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.statusBi)}`
        },
        {
            columnDef: 'EI', header: 'PCD',
            cell: (element: Cassette, index: number) => `${element.barcodeEi ? element.barcodeEi : ''}`,
            textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.statusEi)}`
        },

        {
            columnDef: 'Status', header: 'Status',
            cell: (element: Cassette, index: number) => `${this.getLoadStatus(element)}`
        },
        // `${this.page * 10 + index + 1}`
        { columnDef: 'Remove', header: 'Remove', cell: (element: Cassette, index: number) => `${this.page * 10 + index + 1}` },
    ];
    displayedColumns = this.columns.map(c => c.columnDef);

    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;
    @ViewChild('input', {static: true}) input:any;

    @ViewChild('filter', { static: true })
    filter: ElementRef;
    isLoading = false;
    errorMsg: string;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    page = 0;

    patient: Patient;
    barcode: string;
    searchText: string;
    map = new Map<string, Cassette>();
    isEnterPressed = false;
    isAssignedPressed = false;
    platform = 'web';
    selected: any;
    // Private
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<Cassette[]>;

    constructor(
        private userService: StorageService, private fb: FormBuilder,
        private route: ActivatedRoute, private router: Router,
        private paginationService: UnassignService,
        private patientService: PatientService,
        private changeDetectorRefs: ChangeDetectorRef,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private statusService: StatusService,
        private afs: AngularFirestore,
        public dialog: MatDialog,
        private configService: ConfigService,
        private globals: Globals,
        private apiService: ApiService,
        private firebaseUtilService: FirebaseUtilService,
        private cookieService: CookieService
    ) {
        super(configService, globals);
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }


    @HostListener('document:keydown', ['$event'])
    handleKeyboardEven(event: KeyboardEvent) {
        const key = event.key;
        if (key === "Backspace" || key === "Delete") {
            this.selectedPatient = null;
        }
    }



    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const key = event.key;
        if (event.key.includes('Enter')
            && event.code.includes('Enter')
            && document.activeElement.id.includes('Barcode')) {
            if (this.isAssignedPressed) {
                return;
            }
            this.isEnterPressed = true;
            if (this.barcode.length > 0) {
                this.selectBarcode();
            }
        }
        else {
            this.isEnterPressed = false;
        }
    }

    // added for clear input and refresh data
    clearInputSearch() {
        this.barcode = '';
    }


    ngOnInit(): void {
        this.input.nativeElement.focus();
        this.urlData = this.route.snapshot.queryParams.barcode;
        if (this.urlData !== '' && this.urlData !== undefined) {
            this.barcode = this.urlData;
            this.selectBarcode();
        }
    }

    onClickAssign(): void {

        if (this.dataSource.data.length) {
            const dialogUtil = new DialogUtil();

            let customMsg = 'Are you sure to unassign ';

            dialogUtil.openConfirmationDialog(this.dialog, customMsg
                + this.dataSource.data.length + ' cassette(s)?', {
                onConfirm: () => {
                    this.submit();
                },
                onCancel: () => {},
                onAlways: () => {}
            });
        } else {
            alert('There is no cassette for unassignment');
        }
    }


    submit(): void {
        const list: string[] = [];
        this.selection.selected.forEach(element => {
            if (list.indexOf(element.id) < 0) {
                list.push(element.id);
            }
        });
        if (list.length < 1) {
            this.openSnackBar('Please Select at least one cassette(s)', 'OK');
            return;
        }
        this.assign();
    }

    selectBarcode(): void {
        if (this.barcode === '' || this.barcode === undefined) {
            alert('Please enter barcode first');
            return;
        }
        const value = this.barcode.replace(/\s/g, '');
        this.barcode = '';
        let isCassetteAlready = false;
        this.dataSource.data.forEach(cassette => {
            if (cassette.barcode.includes(value)) {
                isCassetteAlready = true;
            }
        });
        if (isCassetteAlready) {
            alert(' This cassette has been already added ' + value.toUpperCase());
            return;
        } else {

        }

        const subs1 = this.paginationService.getCassetteEntryObserable(value).subscribe(
            data => {
                if (data && data.length > 0) {
                    const cass: Cassette = data[0];
                    this.updateRow(cass);
                    this.enableAssign = true;

                    const eStatus: CassetteStatus = this.statusService.getCassetteStatus(cass);
                    if (eStatus === CassetteStatus.USED || eStatus === CassetteStatus.USED_IN_PROGRESS ) {
                        this.enableAssign = true;
                        this.addCassetteDirect(cass);
                    } else {
                        this.enableAssign = false;
                        alert(`${cass.barcode} is not assigned yet.` + 'Cassette current status is ' + eStatus.valueOf());
                    }
                } else {
                    alert('Cassette not found ' + value.toUpperCase());
                }
                subs1.unsubscribe();
            },
            error => { // dp something
            },
            () => {// dp something
            }
        );
        this.barcode = '';
    }

    addCassetteDirect(cass) {

        this.dataSource.data.push(cass);
        this.dataSource.filter = '';
        this.selection.toggle(cass);
        console.log("sele=", this.selection.selected,this.selection.isSelected)
    }

    assign(): void {
        this.isAssignedPressed = true;
        const time = new Date().getTime();
        const barcodes = [];
        console.log("sele=", this.selection.selected)
        this.selection.selected.forEach(element => {
            barcodes.push(element.barcode);
            this.paginationService.updateCassette(element.id, {
                patientId: '',
                patientName: '',
                isAssignDone: false,
                status: 'Ready',
                assigned: null,
                patient: null,
                updatedBy: this.userService.getCookie("name"),
                dateTimeNum: -1 * time,
                platform: this.platform,
            });
        });
        this.openSnackBar(('Cassette(s) unassigned Successfully.'), 'OK');
        this.selection.clear();
        this.selectedPatient = '';
        while (this.dataSource.data.length > 0) {
            this.dataSource.data.pop();
        }
        this.dataSource.filter = '';

        this.isAssignedPressed = false;
        this.showAlert('Cassette(s) Unassigned Successfully.');
        this.barcode = '';
    }

    showAlert(message): void {
        const dialogUtil = new DialogUtil();
        dialogUtil.openAlertDialog(this.dialog, message, {
            onConfirm: () => {
            },
            onCancel: () => {
            },
            onAlways: () => {

            }
        });
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }
    //
    // updateRow(row): any {
    //     row.date = moment(Math.abs(row.dateTimeNum)).format('DD-MM-YYYY');
    //     row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    // }

    updateRow(row): any {
        row.date = timeZone(Math.abs(row.dateTimeNum)).tz(this.userService.getCookie('defaultTz')).format('DD-MM-YYYY');
        row.time = timeZone(Math.abs(row.dateTimeNum)).tz(this.userService.getCookie('defaultTz')).format('HH:mm');
        // row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    }


        nextPage(): void {
        this.paginationService.next();
    }


    prevPage(): void {
        this.paginationService.prev();
    }


    /**
* On destroy
*/
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    displayFn(patient: Patient) {
        if (patient) {
            return patient.firstName;
        }
    }

    getLoadStatus(load: Cassette): string {
        return this.statusService.getCassetteStatus(load);
    }

    getCassetteResult(load: Cassette): string {
        return this.statusService.getMessage(this.statusService.getStatusNew(load.statusBd, load.statusBi, load.statusEi));
    }

    getLoadColor(load: Cassette): string {
        return this.statusService.getColor(this.statusService.getStatusNew(load.statusBd, load.statusBi, load.statusEi));
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(this.statusService.getStatus(load));
        } else {
            return '#111111';
        }
    }

    // remove row from the to be assigned table
    remove(id) {
        const index = id - 1; // index starts from 0
        // const index = this.dataSource.data.indexOf(id);  // got index
        this.ELEMENT_DATA = this.dataSource.data.splice(index, 1); // what id to delete and which one to delete
        this.dataSource = new MatTableDataSource(this.dataSource.data); // refresh the data
        // removing from the selection list
        this.selection.selected.splice(index, 1);
        console.log("sele=", this.selection.selected)
      
    }
}


