import {
    AfterViewInit,
    ChangeDetectorRef,
    ElementRef,
    HostListener,
    OnDestroy,
    TemplateRef,
    ViewChild,

} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import 'rxjs/add/operator/toPromise';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';







import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthenticationService } from '../../common/service/authentication.service';
import { ProfileService } from './profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ProfileService]
})
export class ProfileComponent implements OnInit {
    userName: string;
    profileImage: any;
    email: any;
    userDataObj: Object;
    editProfileForm: FormGroup;
    url: any;
    showHideEditNameSection: boolean = false;
    updateProfileInfoForm: FormGroup;
    hasFirstDataShown:Boolean;
    
    // userImage: any;
    /**
     * Constructor
     */
    constructor(
        private userService: StorageService,
        private authService: AuthenticationService,
        public profileService: ProfileService,
        public snackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private afStorage: AngularFireStorage,
        private dialog:MatDialog
    ) {

    }
    ngOnInit(): void {
        this.hasFirstDataShown = false;
        this.userName = this.userService.getCookie("name");
        this.email = this.userService.getCookie("email");
        this.getUserData();
        this.editProfileForm = this._formBuilder.group({
            userImage: ['',],
        });
        this.updateProfileInfoForm = this._formBuilder.group({
            // updatedName: ['', [Validators.required, Validators.pattern(/^\S+/)], ''],
            updatedName: ['']
        });
    }
    getUserData() {
        this.authService.getUser(this.email).then(async (userDto) => {
            this.userDataObj = userDto;
            console.log(this.userDataObj);
            this.profileImage = userDto && userDto['userImage'] ? userDto['userImage'] : 'https://www.w3schools.com/howto/img_avatar.png';
            console.log(this.profileImage);
            this.url = userDto && userDto['userImage'];
            console.log("userDto", userDto,this.url);
        });
    }
    showEditNameSection(event) {

        this.showHideEditNameSection = !this.showHideEditNameSection;
    }

    onSelectFile(event) {
        console.log('Event=',event,event.target.files,event.target.files[0]);
         this.hasFirstDataShown = false;
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.url = event.target.result;
            }
            const userImage = event.target.files[0];

            if (userImage) {
                this.editProfileForm.get('userImage').setValue(userImage);
                this.updateProfileImage()
            }
        }

    }
    updateProfileImage() {
        const dataObj = this.userDataObj;
        const fileData = this.editProfileForm.value.userImage;
        dataObj['userImage'] = fileData.name;
        const fileRef = this.afStorage.ref(`/user/${fileData.name}`);
        this.afStorage.upload(`/user/${fileData.name}`, fileData).then(imageData => {
            if (imageData) {
                console.log(imageData);
                fileRef.getDownloadURL().subscribe(url => {
                    if (url) {
                        dataObj['userImage'] = url;
                        this.url = url;
                        console.log('url = '+this.url);
                        this.profileImage=this.url;
                        console.log('this.profileImage = '+this.profileImage);
                        this.profileService.updateUserInfoDetails(this.email, dataObj)
                            .then((data: any) => {
                                const msg = 'Profile image updated successfully';
                                this.openSnackBar(msg, 'OK');
                                // this.hasFirstDataShown = true;
                                // this.getUserData();
                                // this.router.navigate(['/profile']);
                                setTimeout(() => {
                                     window.location.reload();
                                }, 5000)
 
                            }).catch((error) => {
                                this.handleError(error);
                            });
                            // this.getUserData();
                    }
                })
            }
        })
    }
    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }
    private handleError(error: Error): void {
        this.openSnackBar(error.message, 'OK');
        // this.notify.update(error.message, 'error');
    }
    updateProfileInfo(updatedNameRef: HTMLInputElement) {
        const dataObj = this.userDataObj;
        let mystring = updatedNameRef.value;
        mystring = mystring.toString().trim();
        if (mystring && mystring !== '' && mystring.length > 0) {
            dataObj['name'] = mystring
            // this.hasFirstDataShown = true;

            try {
                this.profileService.updateUserInfoInnerDetails(dataObj, this.email);
                // this.hasFirstDataShown = false;
                this.userService.setCookie("name", updatedNameRef.value);
                const msg = 'Profile updated successfully';
                this.openSnackBar(msg, 'OK');
            }
            catch (error) {
                // this.hasFirstDataShown = false;
                this.openSnackBar(error, 'OK');
            }
        }
        else {
            this.openSnackBar("Blank spaces are not allowed", 'OK');
        }

    }
    // openDialogWithRefEdit(ref: TemplateRef<any>): void {

    //     console.log("testrun");
    //     this.updateProfileInfoForm.setValue({
    //         updatedName: 'testuser',

          
    //     });
    //     this.dialog.open(ref, { width: '400px' });

    // }
}
