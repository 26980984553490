import {Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { AuthenticationService } from 'app/main/module/common/service/authentication.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { navigation } from 'app/navigation/navigation';
import { Globals } from '../../../main/module/common/global';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import {MatDialog} from '@angular/material/dialog';
import { AngularFirestore} from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { ReminderhistoryComponent } from 'app/main/module/sterilwize/reminders/reminderhistory/reminderhistory.component';
import { EditReminderDialougeComponent } from 'app/main/module/sterilwize/reminders/edit-reminder-dialouge/edit-reminder-dialouge.component';
import { ReminderslistService } from 'app/main/module/sterilwize/reminders/reminderslist.service';

export interface Lab {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,

})

export class ToolbarComponent implements OnInit, OnDestroy {

    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    email: string;
    userName: string;
    profileImage: any;
    labsData: any = [];
    labsObjData: any = [];
    defaultLab: any;
    staticData: any;
    qrcodePath = '';
    labId = '';
    isLoading= true;
      private remindHis:ReminderhistoryComponent;
    // Private
    private _unsubscribeAll: Subject<any>;


    labs: Lab[] =
        [
            { value: 'Hinduja Mahim', viewValue: 'Hinduja Mahim' },
            { value: 'Hinduja Khar', viewValue: 'Hinduja Khar' },
            { value: 'All Mumbai', viewValue: 'All Mumbai' },
            { value: 'Hinduja Delhi', viewValue: 'Hinduja Delhi' },
            { value: 'Hinduja Banglore', viewValue: 'Hinduja Banglore' },
            { value: 'Hinduja All India', viewValue: 'Hinduja All India' },
            { value: 'Hinduja Europe', viewValue: 'Hinduja Europe' }
        ];
    selected = 'Hinduja Mahim';
    count:  Array<any> = [];
    reminderData: Array<any> = [];
    totalBadges: any;
    private interval: any;
    reminderSelection:  Array<any> = [];
    remindbadges = new FormControl('');
    reminder_interval: number;
    userRemark: string = '';
    taskreminderButton: string;
    // selected = this.labsData[0];

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private globals: Globals,
        private cookieService: CookieService,
        private userService: StorageService,
        private authService: AuthenticationService,
        private dialog: MatDialog,
        private afs: AngularFirestore,
        private remindelistService: ReminderslistService
       
    ) {
        this.getFieldValue()
        this.isLoading = true;
     
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'fr',
                title: 'French',
                flag: 'fr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit() {
        console.log("toolbar calling")
        this.totalBadges = '';
        this.checkReminders();
        this.email = this.userService.getCookie("email");
        this.userName = this.userService.getCookie("name");
        // this.profileImage = this.userService.getCookie("userImage")
        // console.log('ProfileImage=',this.profileImage);
        this.labsData = JSON.parse(localStorage.getItem('labs'));
        this.labsObjData = JSON.parse(localStorage.getItem('labsObject'));
        this.staticData = localStorage.getItem('staticData');
        if (this.labsData) {
            this.defaultLab = this.labsData[0];
        }
        this.getUserData();
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });

    }
    getUserData() {
        if(this.email)
        this.authService.getUser(this.email).then(async (userDto) => {
            this.profileImage = userDto && userDto['userImage'] ? userDto['userImage'] : 'assets/images/avatars/user.png';
        });
    }

    onClickOption(data){
        console.log("calling")
        this.openStatusDialog(data)

    }

    openStatusDialog(data:any) {
console.log("calling status dialouge",data)
        // if (!this.isPopupOpen) {
            // this.isPopupOpen = true;
        const dialogRef = this.dialog.open(EditReminderDialougeComponent, {
        width: '400px',
        // hasBackdrop: true, // Show the overlay
        backdropClass: 'custom-overlay', // Apply a custom CSS class
        data: data
          }); 
          dialogRef.afterClosed().subscribe(result => {
            console.log("calling after dialouge",result);
            if (result === 'Add') {
              this.upadteStatus(data.id);
            } else if (result === 'MarkAsCompleted') {
                this.upadteStatusAsCompleted(data.id); // Example interval of 30 minutes
            }
          });
        // } 
      }

      getFieldValue(): void {
        this.remindelistService.getFieldValue('reminder_interval').subscribe((value) => {
          console.log('Field Value:', value);
          this.reminder_interval = value;
          this.taskreminderButton ="Remind me in "+value+" minute";
          // Use the retrieved value as needed in your component
        });
      }
    
    upadteStatus(reminder_id){
    console.log("reminderId",reminder_id,this.userRemark);
    const currentDate = new Date();
    const newDate = new Date(currentDate.getTime() + this.reminder_interval * 60000);
    const newduedateTimestamp = newDate.getTime();
    console.log("due date =", newduedateTimestamp)
    const newData = {
        status: 'Pending',
        next_due_date: newduedateTimestamp,
        remark:this.userRemark
      };
      this.remindelistService.updateReminderHistory(reminder_id,newData)
        .then(() => {
          console.log('Data updated successfully');
        //   console.log("aaa")
        })
        .catch((error) => {
          console.error('Error updating data:', error);
        });
      }
    
      upadteStatusAsCompleted(reminder_id){
        console.log(reminder_id,this.taskreminderButton);
        const currentDate = new Date();
        const newData = {
            status: 'Completed',
            updated_by:this.userService.getCookie("name"),
            updated_on:currentDate.getTime(),
            remark:this.userRemark
          };
          this.remindelistService.updateReminderHistory(reminder_id,newData)
            .then(() => {
              console.log('Data updated successfully');
            //    this.toolbar.checkReminders();
    
            })
            .catch((error) => {
              console.error('Error updating data:', error);
            });
          }

    checkReminders() {
        
        this._fuseSidebarService.getAllReminderFromHistory().subscribe((data) => {
            this.reminderData = data;
            this.count = [];
            this.reminderSelection = []
            // console.log("ccc",this.reminderData,this.reminderData.length);
            // console.log("lasic 1")
            
            // this.totalBadges = this.reminderData.length;
            this.reminderData.forEach(re=>{
                
                // console.log("re",this.totalBadges);
                // this.reminderSelection.push({taskName:re.task_name,taskDesc:re.task_desc})
                if(re.status == "Pending" && re.platform == "Web"){
                    this.count.push(re);
                    
                    // console.log(this.count.length);
                    this.totalBadges = this.count.length;
                    this.reminderSelection.push({id:re.id,task_name:re.task_name,task_desc:re.task_desc})

                }
            })
          });
      }

      formatTimestamp(timestamp) {
        const date = new Date(timestamp); 
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        // do something
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        this.globals.cassetteConfig = null;
        this.router.navigate(['/login']);
        location.reload(true);

    }
    profileRedirection(): void {
        this.router.navigate(['/profile']);
    }

    openDialogWithRef(ref: TemplateRef<any>) {
        this.isLoading = true;
        this.labId = this.userService.getCookie('lab');
        const collectionPath = `/lab/${this.labId}/config/`;
        const logoPath = this.afs.collection(collectionPath).doc('lab');
        logoPath.ref.get().then(data => {
            const logoPathName = data.data();
            if (logoPathName) {
                this.qrcodePath = logoPathName.qrcodeUrl;
                this.isLoading = false;
            }


        });

            // console.log(this.rowData);
            this.dialog.open(ref, {width: '320px', disableClose: true});

    }

    hideLoader() {
        this.isLoading = false;
    }

}
