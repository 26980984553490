import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { Reminders } from '../reminder';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreatereminderserviceService {

  constructor(private firestore: AngularFirestore,private userServiceChild: StorageService,private http: HttpClient) { }

    // Function to add data to Firestore
    insertData(customDocId:string,data: Reminders): Promise<any> {

      const collectionName = 'lab/' + this.userServiceChild.getCookie("lab") + "/reminders"; // Replace with your collection name
  console.log("added data",data,collectionName)
      // Use the Firestore collection reference to add data
      const docRef = this.firestore.collection(collectionName).doc(customDocId);

      // Set the data at the custom document ID
      return docRef.set(data);;
    }
}
