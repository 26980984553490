import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {StatusService} from '../../status/status.service';
import {MatTableDataSource} from '@angular/material/table';
import {Observable} from 'rxjs';
import { LoadService } from '../../load/load.service';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {ApiService} from '../../../common/service/api.service';


@Component({
  selector: 'app-maintenancedetail',
  templateUrl: './maintenancedetail.component.html',
  styleUrls: ['./maintenancedetail.component.scss'],
  animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class MaintenancedetailComponent implements OnDestroy,OnInit, AfterViewInit {
  urlData = '';
  loadObservableData: Observable<any>;
  cstObservableData: Observable<any>;
  maintenaceDetailsData: any;
  barcode: any;
  program: any;
  status: any;
  cycleNo: any;
  loadNo: any;
  machineName: any;
  startedName: any;
  startedTime: any;
  completedName: any;
  completedTime: any;
  ailinkImage: Array<any>;
  biLinkImages: Array<any>;

  constructor( public loadListService: LoadService,
    private route: ActivatedRoute, private router: Router,
    private location: Location, public dialog: MatDialog, public apiService: ApiService,) { }

  ngOnDestroy(): void {
     this.loadObservableData
}

ngOnInit(): void {
    this.urlData = this.route.snapshot.queryParams.barcode;
    console.log("urldata=",this.urlData)
}


ngAfterViewInit(): void {

    this.loadObservableData = this.loadListService.getmaintenanceById(this.urlData);
    this.loadObservableData.subscribe(resultData => {
      console.log("data",resultData[0])
      this.maintenaceDetailsData = resultData[0];
      this.barcode = resultData[0].barcode;
      this.program = resultData[0].type;
      this.status = resultData[0].status;
      if(resultData[0].cycleNo){
        this.cycleNo = resultData[0].cycleNo;
      }else{
        this.cycleNo = ' -';
      }
     
      this.loadNo = resultData[0].loadNo;
      this.machineName = resultData[0].machineName;
      this.startedName = resultData[0].startedBy.name;
      this.startedTime =  this.apiService.getDateStringMomentByTz(resultData[0].startedBy.time);
      if(this.status=="InProcess"){
      }else{
        this.completedName = resultData[0].updatedBy.name;
        this.completedTime =  this.apiService.getDateStringMomentByTz(resultData[0].updatedBy.time);
      }
    
      this.ailinkImage=resultData[0].aLink
      
      this.biLinkImages =resultData[0].bLink;
      console.log(this.ailinkImage,'ffhjdfhjdv',this.biLinkImages);
      

    })

}

goBack() {
  this.router.navigate(["/maintenanceHistory"])
.then(() => {
//  this.ngOnDestroy();
this.ngOnInit()
//  window.location.reload();
});
}

openDetail(Url) {
  window.open(Url, "_blank");
}

}
