import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ReminderslistService } from '../reminderslist.service';
// import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-reminder-dialouge',
  templateUrl: './edit-reminder-dialouge.component.html',
  styleUrls: ['./edit-reminder-dialouge.component.scss']
})
export class EditReminderDialougeComponent implements OnInit {
  reminder_interval: any;
  taskreminderButton: string;
  // constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
  @Output() close: EventEmitter<string> = new EventEmitter<string>();
  taskDescription: any;
  taskName: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private remindelistService:ReminderslistService,public dialogRef: MatDialogRef<EditReminderDialougeComponent>,) {
    
    console.log('data value',data.id,data.remark,data.task_name)
    this.taskDescription= data.task_desc;
    this.taskName = data.task_name
    // this.
this.getFieldValue();
    
   }

  ngOnInit(): void {
  }

  // closeDialog(action: string) {
  //   console.log("calling close dialouge")
  //   this.close.emit(action);
  // }

  onAddClick(): void {
    console.log("calling close dialouge")
    this.dialogRef.close('Add');
  }

  onMarkAsCompletedClick(): void {
    console.log("calling close dialouge mark")
    this.dialogRef.close('MarkAsCompleted');
  }

  getFieldValue(): void {
    this.remindelistService.getFieldValue('reminder_interval').subscribe((value) => {
      console.log('Field Value:', value);
      this.reminder_interval = value;
      this.taskreminderButton ="Remind me in "+value+" minute";
      // Use the retrieved value as needed in your component
    });
  }

}
