import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { catchError, map, retry } from 'rxjs/operators';
import {StorageService} from "../../common/service/storage.service";
import { reject } from 'lodash';
import { resolve } from 'path';
import { firestore } from 'firebase';
import { Maintenance } from './maintenance';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaintenaceService {

  table = 'history';
  orderBy = 'updatedOn';
  ROWS = 10;
  private searchSubject: BehaviorSubject<Maintenance[]>;
  public searchObservable: Observable<Maintenance[]>;
  public searchedText: string = null;
  currentPage = 0;
  apiURL = 'https://northamerica-northeast1-sterilwize-dev-5ebe8.cloudfunctions.net';
  constructor(private afs: AngularFirestore, private userServiceChild: StorageService,private http: HttpClient) {
      this.table = 'history';
      this.orderBy = 'updatedOn';
  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getdataFromMaintenaceHistory(): Observable<Maintenance> {
    // /lab/awdheshDental/maintanance/result/load
    return this.http.post<Maintenance>(this.apiURL + '/getDocuments', JSON.stringify({ "path": "lab/" + this.userServiceChild.getCookie("lab") + "/maintanance/result/load" }), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }
  
  
  getCollection(ref, queryFn?): Observable<any[]> {
      return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
          return actions.map(a => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id;
              const doc = a.payload.doc;
              return { id, ...data as {}, doc };
          });
      }));
  }
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  // getSearchCollection(history): Observable<any[]> {
  //     if (history) {
  //     return this.getCollection(this.getCollectionPath(), (ref) =>
  //     ref .where("deleted", "==", false).where( this.patientSearchQuery(history.toUpperCase()),
  //     "==",
  //     true
      
  //     )
      
  //     .limit(1000)
      
  //     );
      
  //     // .limit(this.ROWS));
      
  //     } else {
      
  //     return this.getCollection(this.getCollectionPath(), (ref) =>
      
  //     ref.where("deleted", "==", false).limit(this.ROWS)
      
  //     );
      
  //     }
      
  //     }

  // getPatient(history): Observable<Maintenance[]> {
  //     this.searchSubject = new BehaviorSubject([]);
  //     this.searchObservable = this.searchSubject.asObservable();
  //     const ref = this.getSearchCollection(history.toUpperCase())
  //         .subscribe(async data => {
  //             if (data && data.length > 0) {
  //                 this.currentPage = 0;
  //                 this.searchSubject.next(data);
  //             }
  //             // scoresRef.unsubscribe();
  //         });
  //     return this.searchObservable;
  // }

  // getCovidConfig(labId: string) {
  //     const getCollectionPath = 'lab/' + labId + '/covid';
  
  //     return new Promise<any>((resolve, reject) => {
  //         try {
  //             ///common/email-alert
  //             const ref = this.afs.collection(getCollectionPath).doc('config');
  //             ref.get().subscribe((doc: any) => {
  //                 resolve(doc.data());
  //             })
  //         } catch (e) {
  //             reject(e);
  //         }
  //     });
  
  // }

  // getSearchQueryWithText(): any {
  //     return this.getCollection(this.getCollectionPath(), ref => ref
  //         .where(this.patientSearchQuery(this.searchedText.toUpperCase()), '==', true)
  //         .limit(1000));
  // }

  getSearchQueryWithOutText(): any {
      return this.getCollection(this.getCollectionPath(), ref => ref
          .orderBy(this.orderBy)
          .limit(this.ROWS));
  }



  getCollectionPath(): string {
      return 'lab/' + this.userServiceChild.getCookie("lab") + "/maintanance/result/load";
  }


  




  


}
