import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { Observable } from 'rxjs-compat/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ViewreminderserviceService {
  table = 'history';
orderBy = 'updatedOn';
  constructor(private afs: AngularFirestore, private userServiceChild: StorageService,private http: HttpClient) {
    this.table = 'history';
    this.orderBy = 'updatedOn';
  }

  getAllData(): Observable<any[]> {
    const memberCollectionPath = this.getCollectionPath();
    return this.afs
        .collection(memberCollectionPath, (ref) =>
          ref.orderBy('created_on', 'desc')
        )
        .valueChanges();
  }

  // Function to delete a document by its ID
  deleteDocumentById(docId: string) {
    const memberCollectionPath = this.getCollectionPath();
    const docRef = this.afs.collection(memberCollectionPath).doc(docId);
    return docRef.delete();
  }

  getAllReminderFromHistory() {
    const memberCollectionPath = this.getCollectionPath();
    return this.afs.collection(memberCollectionPath).get();
  }
   // Function to update a document by its ID
   updateDocumentById(docId: string, data: any) {
    console.log(docId,data)
    const memberCollectionPath = this.getCollectionPath();
    const docRef = this.afs.collection(memberCollectionPath).doc(docId);
    return docRef.update(data);
  }

  // Function to get a document by its ID
  getReminderById(docId) {
    const memberCollectionPath = this.getCollectionPath();
    return new Promise((resolve, reject) => {
        this.afs.collection(memberCollectionPath).doc(docId).valueChanges().subscribe(data => {
            resolve(data);
        });
    });       
}

getremindersDataById(barcode): Observable<any[]> {
  const memberCollectionPath = this.getCollectionPath();
  return this.afs.collection(memberCollectionPath, ref => ref.where('id', '==', barcode)).snapshotChanges()
      .pipe(map(actions => {
          return actions.map((a: any) => {
              return a.payload.doc.data();
          });
      }));
}
getCollectionPath(){
  const collectionPath = 'lab/' + this.userServiceChild.getCookie("lab") + "/reminders";
  return collectionPath
}
}
