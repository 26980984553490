import {
  AfterViewInit, ApplicationRef,
  ChangeDetectorRef,
  Component,
  ElementRef, HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import {fuseAnimations} from '@fuse/animations';
import { Subscription } from 'rxjs';
import {StorageService} from '../../../common/service/storage.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfigService} from "../../../common/config/config.service";
import {Globals} from "../../../common/global";
import {MatDialog} from "@angular/material/dialog";
 import { OthermachineService } from './othermachine.service';
import { Maintenance } from '../maintenance';
import * as moment from 'moment-timezone';
import { Load } from '../../load/load';
import { CovidService } from 'app/main/module/screening/covid.service';
import { MaintenaceService } from '../maintenace.service';


const timeZone = require('moment-timezone');                                                                                                                                                                                                                          

@Component({
  selector: 'app-othermachine',
  templateUrl: './othermachine.component.html',
  styleUrls: ['./othermachine.component.scss'],
  animations: fuseAnimations
})
export class OthermachineComponent implements OnInit {
  
    patient: string;
    data: Maintenance[] =[];
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns
        .filter((c) => c.hide === false)
        .map((c) => c.columnDef);
    dataSource = new MatTableDataSource<any>(this.data);
    @ViewChild("filter", { static: true })
    filter: ElementRef;
  
    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    // isLoading = true;
    // alertSign = "";
    // enableInfoMsg = false;
    // protected extraCta = {
    //     visibility: false,
    //     text: "",
    // };
  
    page: number;
    hasFirstDataShown = false;
    private dataSubscription: Subscription;

  
  
    constructor(
        private userSevice1Child: StorageService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        public covidService: CovidService,
        public historyService:MaintenaceService,
        public global: Globals,
        public configService: ConfigService,
        private paginationService:OthermachineService
    ) {
        //  super(snackBar, configService, global);
  
        
    }
  
    /**
     * On init
     */
    ngOnInit(): void {
        this.dataSource.data = [];
        this.hasFirstDataShown = false;
        this.dataSubscription =    this.paginationService.getAllData().subscribe((data) => {
            data.forEach(element => {             
if(element.type != undefined && element.type != null ){

    if((element.type != "Waterline" && element.type != "waterline") && (element.CellType != "machine" && element.CellType != "Machine" ) && element.updatedOn != undefined){
        // console.log("dat=",element.CellType)

                this.data.push({
                    cellName: this.replaceWithEmptyCellName(element) ,
                    type: this.replaceWithEmpty(element.type),
                    machineName: element.machineName,
                    cycleNo: element.CycleNo,
                    barcode: '',
                    createdBy: this.replaceWithCreatedBy(element.createdBy),
                    createdOn: this.replaceWithEmptycreted(element),
                    startedBy: undefined,
                    updatedOn: this.getDateStringMomentByTz(element.updatedOn),
                    updatedBy: element.updatedBy,
                    aiResult: element.aiResult,
                    status: '',
                    aiLink: '',
                    id: '',
                    docId: ''
                })

                this.hasFirstDataShown = true;
            }  
        }
            });
          
            // console.log(this.data);
            this.dataSource.data = this.data
          });
        this.page = 0;
       

        // this.initData();
       
    }
  
    ngOnDestroy() {
        if (this.dataSubscription) {
          this.dataSubscription.unsubscribe();
        }
      }
      
    // nextPage(): void {
    //     this.hasFirstDataShown = false;
    //     this.isLoading = true;
    //     this.isNextPressed = true;
    //     this.infiniteScroll.more();
    //     this.hasFirstDataShown = true;
    // }
  
    getRowBackgroundColor(element): string {
        // if (element.deleted == true) {
        //     return "lightgrey";
        // } else
        return "#FFFFFF";
    }
  
    getRowFontColor(element) {
        if (element.deleted == true) {
            return "italic";
        } else return "bold";
    }
  
    getFontColor(element) {
        if (element.deleted == true) {
            return "#808080";
        } else return "#000000";
    }
    getCrossWord(element) {
        if (element.deleted == true) {
            return "line-through";
        } else return "overline";
    }
  
    getColumn(): any[] {

        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                hide: false,
                cell: (element: Load, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "Name",
                header: "Name",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                `${element.cellName}`,
                //   this.replaceWithEmptyCellName(element)
            },
            {
                columnDef: "Type",
                header: "Type",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                `${element.type}`,
                // this.replaceWithEmpty(element.type)
     
            },
          
          //   {
          //       columnDef: "CycleNo",
          //       header: "CycleNo",
          //       hide: false,
          //       cell: (element: Maintenance, index: number) => 
          //     //   `${element.cycleNo}`,
          //     this.replaceWithEmpty(element.cycleNo)
          //   },
  
            {
              columnDef: "MaintenanceNo",
              header: "Created On",
              hide: false,
              cell: (element: Maintenance, index: number) => 
              `${element.createdOn}`,
            //   this.replaceWithEmptycreted(element)
          },
  
          {
            columnDef: "CreatedBy",
            header: "Created By",
            hide: false,
            cell: (element: Maintenance, index: number) =>
             `${element.createdBy}`,
        //   this.replaceWithCreatedBy(element.createdBy)
        },
  
        {
          columnDef: "MaintainedOn",
          header: "Maintained On",
          hide: false,
          cell: (element: Maintenance, index: number) => 
          `${element.updatedOn}`,
          // this.getDateStringMomentByTz(element.updatedOn),
        //   this.getDateStringMomentByTz(element.updatedOn)
      },
  
      {
        columnDef: "MaintainedBy",
        header: "Maintained By",
        hide: false,
        cell: (element: Maintenance, index: number) => 
        `${element.updatedBy}`,
    //   this.replaceWithEmpty(element.updatedBy)
    },
    {
      columnDef: "Result",
      header: "Result",
      hide: false,
      cell: (element: Maintenance, index: number) => 
      `${element.aiResult}`,
    //    this.replaceWithEmpty(element.aiResult)
  },
            
        ];
    }
    replaceWithCreatedBy(createdBy) {
  
      if (createdBy != undefined )  {
          // console.log('name=',createdBy.name,createdBy)
          if( createdBy.name != undefined){
          return createdBy.name
          }
          else
          {
              return ' '
          }
      }
      else { 
          return '-'
      }
  }
  
  replaceWithEmptyCellName(value) {
      // console.log(value);
  
      if (value.cellName != undefined && value.cellName != null)  {
          // console.log('name=',createdBy.name,createdBy)
         return value.cellName
      }
      else { 
          return value.cellId
      }
  }
  
  replaceWithEmptycreted(value) {
    //   console.log(value);
      if (value.createdBy != undefined && value.createdBy != null)  {
        //   console.log('If calling')
          if(value.createdBy.time != undefined){
          return this.getDateStringMomentByTz(value.createdBy.time)
          } 
                
      }
      else { 
        // console.log('else calling')
        // console.log("cure=",this.getDateStringMomentByTz(value.currentDate))
          return this.getDateStringMomentByTz(value.currentDate)
      }
  }
  
  replaceWithEmpty(value) {
      const exitingValue= value;
      
       if (value == undefined) {
        
           return '-';
       }
       else {
           return exitingValue
       }
   }
  
   getDateStringMomentByTz(timestamp: any): string {
    if(timestamp){
        if (timestamp < 0)
            timestamp = timestamp * -1;
        const localTimeZone =  moment(timestamp).tz(this.userSevice1Child.getCookie('defaultTz'));
        return localTimeZone.format('DD-MM-YYYY | HH:mm');
    }

}


    getStringOrDefault(reqkey: any, defaulvalue: any) {
        const rg = defaulvalue;
        try {
            if (reqkey === undefined || reqkey === null) {
                return defaulvalue;
            } else {
                return reqkey;
            }
        } catch (error) {
            rg == defaulvalue;
        }
    }
  
    
  
    
  
  
  
    updateRow(row): any {
        // setDeriveFields(row);
    }
  
    // ngOnDestroy() {
    //     this.subscription.unsubscribe();
    //     OthermachineComponent.oldData = this.dataSource.data;
    //     // OthermachineComponent.cursor = this.infiniteScroll.getCursor();
    // }
  
    getTitleMatIcon(): string {
        return "art_track";
    }
  
    getTitle(): string {
        return "Other Maintenance History";
    }
  
    getSearchText(): string {
        return "Search Patient via name";
    }
  
   
  
   
  
    addZeroToHourAndMinutes(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        const strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
        // if (time < 10) {
        //     return '0' + time;
        // }
        // return time;
    }
  
    getMapValue(stringData) {
        const param = stringData.replace(/[^a-zA-Z0-9]/g, "");
        const obj = {};
        for (let j = 0; j <= param.length - 1; j++) {
            let emptyString = "";
            for (let k = 0; k <= j; k++) {
                emptyString += param[k];
            }
  
            obj[emptyString] = true;
        }
        return obj;
    }
  
    toUpperCaseString(str) {
        return str.trim().toUpperCase();
    }
  
  
    private handleError(error: Error): void {
        // this.openSnackBar(error.message, "OK");
        // this.notify.update(error.message, 'error');
    }
  
  
  
  
    changeTimestampToDate(timestamp) {
        if (timestamp) {
            // new Date('03/17/2021');
            // return timeZone(timestamp).tz(this.userSevice1Child.getCookie('defaultTz')).format('DD/MM/YYYY');
            return moment(timestamp).format("DD/MM/YYYY | HH:mm");
        }
        return "";
    }
  
    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        // this.handleEnterSearch(event);
    }
  
    // getSubscriptionType() {
    //     console.log("navType", this.navigationType);
    //     switch (this.navigationType) {
    //         case "screening":
    //             return true;
    //         default:
    //             return false;
    //     }
    // }
  }
  
