import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import "rxjs/add/operator/toPromise";
import { fuseAnimations } from "@fuse/animations";
import { StorageService } from "../../../common/service/storage.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { Load } from "../../load/load";
import { CovidService } from "../../../screening/covid.service";
import { Globals } from "../../../common/global";
import { TeamService } from "../../team/team.service";
import * as moment from "moment-timezone";
import { ConfigService } from "../../../common/config/config.service";
import { FirebaseUtilService } from "../../../common/service/FirebaseUtil.service";
import { ApiService } from "../../../common/service/api.service";
const timeZone = require("moment-timezone");
import { Subscription } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { Style } from "util";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MaintenanceHistoryInfiniteScrollService } from "./maintenance-history-infinite-scroll.service";

import { Patient } from "../../patient/patient";
import { AddPatientDialogComponent } from "../../patient/addpatient/add-patient-dialog.component";
import { ShareAssessmentDialogComponent } from "../../patient/shareAssessmentLink/share-assessment-dialog.component";
import { SuccessfulComponent } from "../../patient/successful/successful.component";
import { EditPatientDialogComponent } from "../../patient/editpatient/edit-patient-dialog.component";
import { MaintenaceService } from "../maintenace.service";
import { Maintenance } from "../maintenance";
import { forEach } from "lodash";
import { element } from "protractor";

@Component({
    selector: 'app-maintenance-history',
    templateUrl: './maintenance-history.component.html',
    styleUrls: ['./maintenance-history.component.scss'],
    animations: fuseAnimations

})
export class MaintenanceHistoryComponent implements OnInit {

    patient: string;
    //   navigationType = this.route.snapshot.data.navType;
    navigationType = 'all'
    checkHide = this.getSubscriptionType();
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns
        .filter((c) => c.hide === false)
        .map((c) => c.columnDef);
    public dataSource = new MatTableDataSource<any>();
    @ViewChild("filter", { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    isLoading = true;
    alertSign = "";
    enableInfoMsg = false;
    protected extraCta = {
        visibility: false,
        text: "",
    };

    points = [];
    signatureImage;
    staffDropDown: any;
    staffForm: FormGroup;
    staffName = "";

    perPageData = this.global.perPageData;

    token: number;
    queryParam = {};

    isInitComplete = false;
    subscription: Subscription;
    dataN: Array<any> = [];
    isNextPressed = false;
    todayDate: Date = new Date();
    static oldData: Array<any> = [];
    static cursor: any;
    isMoreDataAvailable = true;
    pmsIntegration: boolean;
    loadingSpinner: boolean = true;
    labConfig: any;
    dateTime: any;
    getCountrycode: any = 0;
    page: number;
    searchText: string;
    paginationService: any;
    history: any;
    totaldata: any;
    hasFirstDataShown = false;


    constructor(
        private userSevice1Child: StorageService,
        private fb1: FormBuilder,
        private route1: ActivatedRoute,
        private router1: Router,
        private changeDetectorRefs1: ChangeDetectorRef,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private infiniteScroll: MaintenanceHistoryInfiniteScrollService,
        private changeDetectorRefs: ChangeDetectorRef,
        public snackBar: MatSnackBar,
        public covidService: CovidService,
        public historyService: MaintenaceService,
        public global: Globals,
        public configService: ConfigService,
        public team: TeamService,
        private firebaseUtilService: FirebaseUtilService,
        private httpClient: HttpClient,
        private apiService: ApiService
    ) {
        //  super(snackBar, configService, global);


    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.page = 0;
        this.initData();

    }

    initData() {
        if (MaintenanceHistoryComponent.oldData && MaintenanceHistoryComponent.oldData.length > 0) {
            this.isLoading = false;
            this.changeDetectorRefs.markForCheck();
            this.changeDetectorRefs.detectChanges();
            this.dataN = MaintenanceHistoryComponent.oldData;
            this.dataSource = new MatTableDataSource(this.dataN);
            //   console.log('datasource=',this.dataSource.data);
            this.dataSource.data = this.dataN;
            this.isInitComplete = true;
            this.isLoading = false;
            this.initWithOldData();
            return;
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.infiniteScroll.init("history", 10, "startTimeNum", {
            reverse: false,
            prepend: false,
        });

        this.subscription = this.infiniteScroll.data.subscribe((data) => {
            if (data) {
            } else return;
            this.dataN = data;
            if (
                this.dataSource &&
                this.dataN &&
                this.dataSource.data.length == this.dataN.length
            ) {
                this.isMoreDataAvailable = false;
            }
            try {
                data.forEach((value) => this.updateRow(value));
            } catch (e) { }

            this.dataSource = new MatTableDataSource(this.dataN);
            this.dataSource.data = this.dataN;
            if (this.dataN && this.dataN.length > 0) {
                this.isInitComplete = true;
                this.isLoading = false;
                this.hasFirstDataShown = false;
            }
            if (!this.dataN || data.length === 0) {
                setTimeout(() => {
                    this.isInitComplete = true;
                    this.hasFirstDataShown = true;
                }, 6000);
            } else {
            }
            setTimeout(() => {
                this.scrollToBottom();

                this.isLoading = false;
            }, 200);
        });
    }

    initWithOldData() {
        this.infiniteScroll.initWithPrev(
            MaintenanceHistoryComponent.cursor,
            MaintenanceHistoryComponent.oldData,
            "history",
            10,
            "startTimeNum",
            { reverse: false, prepend: false }
        );

        this.subscription = this.infiniteScroll.data.subscribe((data) => {
            if (data) {
            }
            else return;
            this.dataN = data;
            //   console.log('data sterilizer',this.dataN)
            if (
                this.dataSource &&
                this.dataN &&
                this.dataSource.data.length - MaintenanceHistoryComponent.oldData.length ==
                this.dataN.length
            ) {
                console.log(
                    "isMoreDataAvailable" +
                    "this.dataSource.data.length" +
                    this.dataSource.data.length +
                    "this.dataN.length" +
                    this.dataN.length
                );
                // this.isMoreDataAvailable = false;
            }
            data.forEach((value) => this.updateRow(value));
            this.dataSource = new MatTableDataSource(this.dataN);

            this.dataSource.data = MaintenanceHistoryComponent.oldData.concat(this.dataN);
            if (this.dataSource.data && this.dataSource.data.length > 0) {
                this.isInitComplete = true;
                this.isLoading = false;
            }
            if (!this.dataN || data.length === 0) {
                setTimeout(() => {
                    this.isInitComplete = true;
                }, 6000);
            }
            setTimeout(() => {
                this.scrollToBottom();

                this.isLoading = false;
            }, 1000);
        });
        setTimeout(() => {
            this.scrollToBottom();
            this.isLoading = false;
            this.changeDetectorRefs.markForCheck();
            this.changeDetectorRefs.detectChanges();
        }, 1000);
    }

    scrollToBottom(): void {
        if (this.isNextPressed) {
            this.isNextPressed = false;
            const table = document.querySelector("mat-table");
            table.scrollBy({
                behavior: "smooth",
                left: 0,
                top: table.scrollHeight,
            });
        }
    }

    nextPage(): void {
        this.isLoading = true;
        this.isNextPressed = true;
        this.infiniteScroll.more();
    }

    getRowBackgroundColor(element): string {
        // if (element.deleted == true) {
        //     return "lightgrey";
        // } else
        return "#FFFFFF";
    }

    getRowFontColor(element) {
        if (element.deleted == true) {
            return "italic";
        } else return "bold";
    }

    getFontColor(element) {
        if (element.deleted == true) {
            return "#808080";
        } else return "#000000";
    }
    getCrossWord(element) {
        if (element.deleted == true) {
            return "line-through";
        } else return "overline";
    }

    getColumn(): any[] {
        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                hide: false,
                cell: (element: Load, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "Name",
                header: "Machine Name",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    //   `${element.cellName}`,
                    this.replaceWithEmpty(element.machineName)
            },
            {
                columnDef: "CycleNo",
                header: "Cycle No",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    //   `${element.cycleNo}`,
                    this.replaceWithEmpty(element.cycleNo)
            },
            
            {
                columnDef: "Type",
                header: "Type",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    //   `${element.type}`,
                    this.replaceWithEmpty(element.type)

            },
            {
                columnDef: "MaintenanceNo",
                header: "Maintenance No",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    // `${element.barcode}`,
                    this.replaceWithEmpty(element.barcode)
            },

            {
                columnDef: "Created On",
                header: "Created On",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    // `${element.updatedOn}`,
                    // this.getDateStringMomentByTz(element.updatedOn),
                    this.getDateStringMomentByTz(element.startedBy.time)
            },

            {
                columnDef: "CreatedBy",
                header: "Created By",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    //    `${element.createdBy.name}`,
                    this.replaceWithCreatedBy(element.startedBy)
            },


            {
                columnDef: "MaintainedOn",
                header: "Updated On",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    // `${element.updatedOn}`,
                    // this.getDateStringMomentByTz(element.updatedOn),
                    this.getupdatedbytime(element.updatedBy)
            },


            {
                columnDef: "MaintainedBy",
                header: "Updated By",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    //   `${element.updatedBy}`,
                    this.replaceWithEmptyupdated(element.updatedBy)
            },
            {
                columnDef: "Result",
                header: "Result",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    // `${element.aiResult}`,
                    this.replaceWithEmpty(element.status)
            },

            // {
            //     columnDef: 'details', header: 'Details',
            //     cell: (element: Maintenance, index: number) => `${element.barcode}`
            // },
            {
                columnDef: "aiimages",
                header: " ",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${element.barcode ? element.barcode : ''}`,

            },

        ];
    }


    viewMaintenaceDetail(barcode) {
        console.log("barcode===", barcode)
        this.router.navigate(['/maintenancedetail'], { queryParams: { barcode: barcode } });

    }

    replaceWithCreatedBy(startedBy) {
        // console.log(startedBy)

        if (startedBy != undefined) {
            // console.log('name=',createdBy.name,createdBy)
            if (startedBy.name != undefined) {
                return startedBy.name
            }
            else {
                return ' '
            }
        }
        else {
            return '-'
        }
    }


    replaceWithUpdatedBy(updatedBy) {
        // console.log(updatedBy)

        if (updatedBy != undefined) {
            // console.log('name=',createdBy.name,createdBy)
            if (updatedBy.name != undefined) {
                return updatedBy.name
            }
            else {
                return ' '
            }
        }
        else {
            return '-'
        }
    }

    replaceWithEmptyupdated(updatedby) {
        if (updatedby != undefined) {

            const exitingValue = updatedby.name;

            if (updatedby.name == undefined) {

                return '-';
            }
            else {
                return exitingValue
            }
        }
        else {

            return '-';

        }

    }

    replaceWithEmpty(value) {


        const exitingValue = value;

        if (value == undefined) {

            return '-';
        }
        else {
            return exitingValue
        }

    }

    getupdatedbytime(updatedBy) {

        if (updatedBy != undefined) {
            // console.log('name=',createdBy.name,createdBy)
            if (updatedBy.time != undefined) {
                const time = this.getDateStringMomentByTz(updatedBy.time)
                return time
            }
            else {
                return ' '
            }
        }
        else {
            return '-'
        }

    }
    getDateStringMomentByTz(timestamp): string {
        if (timestamp != undefined) {
            if (timestamp) {
                if (timestamp < 0)
                    timestamp = timestamp * -1;
                const localTimeZone = moment(timestamp).tz(this.userSevice1Child.getCookie('defaultTz'));
                return localTimeZone.format('DD-MM-YYYY | HH:mm');
            }
        }
        else {
            return '';
        }
    }
    getStringOrDefault(reqkey: any, defaulvalue: any) {
        const rg = defaulvalue;
        try {
            if (reqkey === undefined || reqkey === null) {
                return defaulvalue;
            } else {
                return reqkey;
            }
        } catch (error) {
            rg == defaulvalue;
        }
    }







    updateRow(row): any {
        // setDeriveFields(row);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        MaintenanceHistoryComponent.oldData = this.dataSource.data;
        MaintenanceHistoryComponent.cursor = this.infiniteScroll.getCursor();
    }

    getTitleMatIcon(): string {
        return "art_track";
    }

    getTitle(): string {
        return "Sterilizers Maintenance History";
    }

    getSearchText(): string {
        return "Search Patient via name";
    }





    addZeroToHourAndMinutes(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        const strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
        // if (time < 10) {
        //     return '0' + time;
        // }
        // return time;
    }

    getMapValue(stringData) {
        const param = stringData.replace(/[^a-zA-Z0-9]/g, "");
        const obj = {};
        for (let j = 0; j <= param.length - 1; j++) {
            let emptyString = "";
            for (let k = 0; k <= j; k++) {
                emptyString += param[k];
            }

            obj[emptyString] = true;
        }
        return obj;
    }

    toUpperCaseString(str) {
        return str.trim().toUpperCase();
    }


    private handleError(error: Error): void {
        // this.openSnackBar(error.message, "OK");
        // this.notify.update(error.message, 'error');
    }




    changeTimestampToDate(timestamp) {
        if (timestamp) {
            // new Date('03/17/2021');
            // return timeZone(timestamp).tz(this.userSevice1Child.getCookie('defaultTz')).format('DD/MM/YYYY');
            return moment(timestamp).format("DD/MM/YYYY | HH:mm");
        }
        return "";
    }

    protected getPaginationService() {
        return this.paginationService;
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        // this.handleEnterSearch(event);
    }

    getSubscriptionType() {
        console.log("navType", this.navigationType);
        switch (this.navigationType) {
            case "screening":
                return true;
            default:
                return false;
        }
    }
}
