import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Reminders } from '../reminder';
import { CreatereminderserviceService } from './createreminderservice.service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { Moment } from 'moment';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-createreminder',
  templateUrl: './createreminder.component.html',
  styleUrls: ['./createreminder.component.scss'],
  animations: fuseAnimations
})
export class CreatereminderComponent implements OnInit {
  private toolbar:ToolbarComponent;
  // date = new FormControl(new Date());
  // dateFilter = (date: Date) => date >= new Date();
  minDate=new Date();
  taskForm: FormGroup;
  createdData: Reminders ;
  sheduleType: string;
  isInitComplete: boolean;
  taskDesc: any;
  taskName: any;
  sheduledDate: any;
  noOfDays: any;
  nextDueDate: number;
  sheduledateHour: string;
  constructor(private fb: FormBuilder,private paginationService: CreatereminderserviceService,private userService:StorageService,
    public snackBar: MatSnackBar,private dialog: MatDialog,) {
    this.taskForm = this.fb.group({
      taskName: ['', Validators.required],
      taskDescription: ['',Validators.required],
      type: ['', Validators.required],
      date: [null, Validators.required],
      time: [" "],
      days: [" "],
    });
  }

  
  ngOnInit() {
        this.minDate = new Date();
  }
  onSubmit() {
    this.isInitComplete = false;
    if (this.taskForm.valid) {
      if(this.taskForm.value.type == 'Once'){
      const dateMoment = this.taskForm.value.date;
      this.sheduledateHour = dateMoment.format('DD-MM-YYYY');
      const timestamp = dateMoment.valueOf();
      const momentObject = moment(timestamp);
      const newMomentObject = momentObject.add(1, 'days');
      const newTimestamp = newMomentObject.valueOf();
      this.nextDueDate = newTimestamp; 
      }

      else{
        const dateMoment = moment(this.taskForm.value.date);
        this.sheduledateHour = dateMoment.format('DD-MM-YYYY');
        const afteraddDays = dateMoment.add(this.taskForm.value.days, 'days'); 
        const timestamp = afteraddDays.unix()*1000;
        const momentObject = moment(timestamp);
        const newMomentObject = momentObject.add(1, 'days');
        const newTimestamp = newMomentObject.valueOf();
        this.nextDueDate = newTimestamp;
      }
      
      if(this.taskForm.value.time == null || this.taskForm.value.time == " " ){
        this.sheduleType = "Post SOD"
      }
      else{
        this.sheduleType = "Fixed"
      }


      const time = new Date().getTime();
      const id = "RMNDR_" +time;
      this.createdData = {
        created_by: this.userService.getCookie('name'),
        created_on:time ,
        id:"RMNDR_" +time,
        next_due_date: this.nextDueDate  ,
        no_of_days:this.taskForm.value.days,
        platform:"Web",
        reminder_id:"RMNDR_"+time ,
        reminder_type:this.taskForm.value.type,
        schedule_date:this.sheduledateHour,
        schedule_epoch_timestamp: this.nextDueDate,
        schedule_time: this.taskForm.value.time,
        schedule_type:this.sheduleType,
        status: null,
        task_desc:this.taskForm.value.taskDescription,
        task_name: this.taskForm.value.taskName,
        updated_by: null,
        updated_on:null,
      }
      // console.log(formData); 
       this.paginationService.insertData(id,this.createdData)
       .then(() => {
         console.log('Data added successfully');
         this.openSnackBar('Reminder added successfully','OK')
         this.taskForm.reset();
         this.isInitComplete = true;
        // this.toolbar.ngOnInit()
       })
       .catch((error) => {
         console.error('Error adding data:', error);
       });

    }
  }

  openSnackBar(message: string, action: string): void { 
    this.snackBar.open(message, action, {
        duration: 6000,
    });
}

openDialougeCreated(ref: TemplateRef<any>) {
  console.log(this.taskForm.value.time,this.taskForm.value.type,this.taskForm.value.date);
// console.log("calling status completed",reminder_id,task_desc)
this.taskDesc = this.taskForm.value.taskDescription
this.sheduledDate = this.taskForm.value.date
this.taskName = this.taskForm.value.taskName
this.noOfDays = this.taskForm.value.days
console.log("no.of days",this.noOfDays,this.taskDesc,this.sheduledDate,this.taskName)
  this.dialog.open(ref, { width: '500px',height:'200px', disableClose: true });
}

}
