import { ApplicationRef, ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { InfiniteremlistService } from './infiniteremlist.service';
import { StatusService } from '../../status/status.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigService } from 'app/main/module/common/config/config.service';
import { Globals } from 'app/main/module/common/global';
import { ContentObserver } from '@angular/cdk/observers';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { Reminders } from '../reminder';
import * as moment from 'moment-timezone';
import { ApiService } from 'app/main/module/common/service/api.service';
import { ReminderslistService } from '../reminderslist.service';
import { EditReminderDialougeComponent } from '../edit-reminder-dialouge/edit-reminder-dialouge.component';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';

const timeZone = require('moment-timezone');

@Component({
  selector: 'app-reminderhistory',
  templateUrl: './reminderhistory.component.html',
  styleUrls: ['./reminderhistory.component.scss'],
  animations: fuseAnimations
})
export class ReminderhistoryComponent implements OnInit {
 private toolbar:ToolbarComponent;
  protected searchText: string;
  hasSearchedText = false;
  public dataCount:number;
  searchTxt = '';
  isLoading = false;
  dataSource: MatTableDataSource<Element>;
  isInitComplete = false;
  isMoreDataAvailable = true;
  limit = 10;
  perPageData = this.globals.perPageData;
  columns = this.getColumn();
  displayedColumns = this.columns.map(c => c.columnDef);
  isEnterPressed = false;
  subscription: Subscription;
  dataN: Array<any> = [];
  static isComeBack = false;


  @ViewChild('filter', {static: true})
  filter: ElementRef;

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  isNextPressed = false;
  todayDate: Date = new Date();
  static oldData: Array<any> = [];
  static cursor: any




  @ViewChild('table')
  myTable: MatTable<any>; /*not exatly sure what the type should be set too */
  loadSearchBarcode: Array<any>;
  searchData: string;
  page: number;
  hasFirstDataShown = false;
 reminderId: any;
 userRemark: string = '';
taskDesc: any;
taskreminderButton:string;
isPopupOpen: boolean = false;
    reminder_interval: any;
    private remindersSubscription: Subscription;
    showPopup: boolean = false;
    private interval: any;
  taskName: any;
  constructor(
      private userSevice: StorageService,
      //  private fb: FormBuilder,
     
      private paginationService: InfiniteremlistService,
      // public loadListService: WaterlineService,
      private changeDetectorRefs: ChangeDetectorRef,
      private apprRefs: ApplicationRef,
      private statusService: StatusService,
      private configService: ConfigService,
      private route: ActivatedRoute, private router: Router,
      public snackBar: MatSnackBar,
      private globals: Globals,
      private readonly contentObserver: ContentObserver,
      private dialog: MatDialog,
      private apiService:ApiService,
      private remindelistService:ReminderslistService) {
        this.getFieldValue();
        // this.checkReminders();
        
        this.interval = setInterval(() => this.checkReminders(), 5000);
  }

  ngOnInit(){  
    this.isPopupOpen=false
    this.dataN = [];
    this.page = 0;   
      this.initData();
      
  }


  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  checkReminders() {
    this.remindelistService.getAllReminderFromHistory().subscribe(reminders => {
      reminders.forEach(reminder => {
        const data = reminder.data() as any;
        const date = new Date();
        // const formattedDate = this.formatTimestamp(date.getTime());
        // console.log("history=", data.next_due_date,date.getTime())
        if ( data.status == "Pending" && data.platform == "Web") {
          if(data.next_due_date <= date.getTime()){
              if(this.isPopupOpen == false){
                this.openStatusDialog(data);}
          }
          
        }
      });
    });
  }
  openDetail(Url) {
    window.open(Url, "_blank");
}

formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}


isDueNow(nextDueDate: any, currentTime: any): boolean {
   const afterConvert =this.getDateStringMomentByTz(currentTime)
   console.log("afetr convert =", afterConvert,nextDueDate,currentTime);
    return nextDueDate == currentTime;
  }

getFieldValue(): void {
    this.remindelistService.getFieldValue('reminder_interval').subscribe((value) => {
      console.log('Field Value:', value);
      this.reminder_interval = value;
      this.taskreminderButton ="Remind me in "+value+" minute";
    });
  }


  openStatusDialog(data:any) {
    const dialogRef = this.dialog.open(EditReminderDialougeComponent, {
    width: '400px',
    // hasBackdrop: true, // Show the overlay
    backdropClass: 'custom-overlay', 
    data: data
      }); 
      dialogRef.afterClosed().subscribe(result => {
        console.log("calling after dialouge",result);
        if (result === 'Add') {
          this.upadteStatus(data.id);
        } else if (result === 'MarkAsCompleted') {
            this.upadteStatusAsCompleted(data.id); // Example interval of 30 minutes
        }
      });
      this.isPopupOpen = true;
    // } 
  }

upadteStatus(reminder_id){
  this.isPopupOpen = false;
console.log(reminder_id,this.taskreminderButton);
const currentDate = new Date();
console.log("current date=",currentDate.getTime())
const date = new Date(currentDate.getTime());
date.setMinutes(date.getMinutes() + this.reminder_interval);

console.log(date.getTime());
const newDate = new Date(currentDate.getTime() + this.reminder_interval * 60000);
const newduedateTimestamp = date.getTime();
console.log("due date =", newduedateTimestamp)
const newData = {
    status: 'Pending',
    next_due_date: newduedateTimestamp,
    remark:this.userRemark
  };
  this.remindelistService.updateReminderHistory(reminder_id,newData)
    .then(() => {
      console.log('Data updated successfully');
      setTimeout(() => {
        this.ngOnInit();
      }, 10000);
    })
    .catch((error) => {
      console.error('Error updating data:', error);
    });
  }

  upadteStatusAsCompleted(reminder_id){
    this.isPopupOpen = false;
    // console.log(reminder_id,this.taskreminderButton);
    const currentDate = new Date();
    const newData = {
        status: 'Completed',
        updated_by:this.userSevice.getCookie("name"),
        updated_on:currentDate.getTime(),
        remark:this.userRemark
      };
      this.remindelistService.updateReminderHistory(reminder_id,newData)
        .then(() => {
          console.log('Data updated successfully');
        setTimeout(() => {
            this.ngOnInit();
          }, 10000);
      
        })
        .catch((error) => {
          console.error('Error updating data:', error);
        });
      }

initData() {
    if(ReminderhistoryComponent.oldData && ReminderhistoryComponent.oldData.length > 0){
        this.isLoading = false;
        this.changeDetectorRefs.markForCheck()
        this.changeDetectorRefs.detectChanges()
        this.dataN = ReminderhistoryComponent.oldData;
        this.dataSource = new MatTableDataSource(this.dataN);
        this.dataSource.data = this.dataN;
        this.isInitComplete = true;
        this.isLoading = false;
        this.initWithOldData()
        return
    }
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
    this.paginationService.init('history', this.limit, 'updatedOn', {reverse: false, prepend: false})

    this.subscription = this.paginationService.data.subscribe(data => {
        if(data){} else return
                    this.dataN = data;
        if (this.dataSource && this.dataN && this.dataSource.data.length == this.dataN.length) {
            this.isMoreDataAvailable = false;
        }
        try{
            data.forEach(value => this.updateRow(value));
        } catch (e){}

        this.dataSource = new MatTableDataSource(this.dataN);
        this.dataSource.data = this.dataN;
        if(this.dataN && this.dataN.length > 0){
            this.isInitComplete = true;
            this.isLoading = false;
        }
        if (!this.dataN || data.length === 0) {
            setTimeout(() => {
                this.isInitComplete = true;
            }, 6000)
        } else {

        }
        setTimeout(() => {
            this.scrollToBottom();

            this.isLoading = false;
        }, 200)
    });
}

initWithOldData(){
    this.paginationService.initWithPrev(ReminderhistoryComponent.cursor, ReminderhistoryComponent.oldData,
        'history', this.limit, 'updatedOn', {reverse: false, prepend: false})

    this.subscription = this.paginationService.data.subscribe(data => {
        if(data){} else return

        this.dataN = data;
        if (this.dataSource && this.dataN &&
            (this.dataSource.data.length - ReminderhistoryComponent.oldData.length)  == this.dataN.length) {
            console.log('isMoreDataAvailable' + 'this.dataSource.data.length'  +this.dataSource.data.length
            + 'this.dataN.length'  +this.dataN.length)
            // this.isMoreDataAvailable = false;
        }
        data.forEach(value => this.updateRow(value));
        this.dataSource = new MatTableDataSource(this.dataN);
        this.dataSource.data = ReminderhistoryComponent.oldData.concat(this.dataN);
        if(this.dataSource.data && this.dataSource.data.length > 0){
            this.isInitComplete = true;
            this.isLoading = false;
        }
        if (!this.dataN || data.length === 0) {
            setTimeout(() => {
                this.isInitComplete = true;
            }, 6000)
        }
        setTimeout(() => {
            this.scrollToBottom();

            this.isLoading = false;
        }, 1000)
    });
    setTimeout(() => {
        this.scrollToBottom();
        this.isLoading = false;
        this.changeDetectorRefs.markForCheck()
        this.changeDetectorRefs.detectChanges()
    }, 1000)
}

ngOnChanges() {
    console.log('ngOnChanges'); 
    this.perPageData = 1;// sets page to 1
    this.dataN = [];   
    // reset array that populate paging
    // initiate search request to API
}

scrollToBottom(): void {
    if (this.isNextPressed) {
        this.isNextPressed = false;
        const table = document.querySelector('mat-table');
        table.scrollBy({behavior: 'smooth', left: 0, top: table.scrollHeight});
    }

}
updateRow(row): any {
  if(row) {
      row.date = timeZone(Math.abs(row.startTimeNum)).tz(this.userSevice.getCookie('defaultTz')).format('DD-MM-YYYY');
      row.time = timeZone(Math.abs(row.startTimeNum)).tz(this.userSevice.getCookie('defaultTz')).format('HH:mm');
  }
}

tableContentChanged() {
    const table = document.querySelector('mat-table');
    table.scrollBy(0, table.scrollHeight);
}
getOrderByColumn(): string {
  return 'doaTimestamp';
}

getTableName(): string {
  return 'patientResult';
}

 ngAfterViewInit(): void {
}



getColumn(): any[] {

  return [
      {
          columnDef: "Sr",
          header: "Sr.",
          hide: false,
          cell: (element: Reminders, index: number) =>
              `${this.page * 10 + index + 1}`,
      },
      {
          columnDef: "Name",
          header: "Task Name",
          hide: false,
          cell: (element: Reminders, index: number) =>
          `${element.task_name}`,
          //   this.replaceWithEmptyCellName(element)
      },
      {
          columnDef: "Type",
          header: "Type",
          hide: false,
          cell: (element: Reminders, index: number) =>
          `${element.reminder_type}`,
          // this.replaceWithEmpty(element.type)

      },
    
    //   {
    //       columnDef: "CycleNo",
    //       header: "CycleNo",
    //       hide: false,
    //       cell: (element: Maintenance, index: number) => 
    //     //   `${element.cycleNo}`,
    //     this.replaceWithEmpty(element.cycleNo)
    //   },

      {
        columnDef: "MaintenanceNo",
        header: "Sheduled Date",
        hide: false,
        cell: (element: Reminders, index: number) => 
        // `${element.schedule_date}`
        `${element.schedule_date}`,
      //   this.replaceWithEmptycreted(element)
    },

    {
      columnDef: "duedate",
      header: "Due Date|Time",
      hide: false,
      cell: (element: Reminders, index: number) =>
    //   `${element.next_due_date}`
       `${this.getDateStringMomentByTz(element.next_due_date)}`,
  //   this.replaceWithCreatedBy(element.createdBy)
  },

  {
    columnDef: "MaintainedOn",
    header: "Updated On",
    hide: false,
    cell: (element: Reminders, index: number) => 
    // `${element.updated_on}`,
    this.getDateStringMomentByTz(element.updated_on),
  //   this.getDateStringMomentByTz(element.updatedOn)
},

{
  columnDef: "updatedBy",
  header: "Updated By",
  hide: false,
  cell: (element: Reminders, index: number) => 
  `${element.updated_by}`,
//   this.replaceWithEmpty(element.updatedBy)
},

{
    columnDef: "reminderstatus",
    header: "Status",
    hide: false,
    cell: (element: Reminders, index: number) => 
    `${element.status}`,
  //   this.replaceWithEmpty(element.updatedBy)
  },


{
  columnDef: "reminderdetail",
  header: "More Detail",
  hide: false,
  cell: (element: Reminders, index: number) => 
  `${element.reminder_id}`,
  //    this.replaceWithEmpty(element.aiResult)
  },

  {
    columnDef: "deletereminder",
    header: " ",
    hide: false,
    cell: (element: Reminders, index: number) => 
    `${element.reminder_id,element.task_desc}`,
    //    this.replaceWithEmpty(element.aiResult)
    },
      
  ];
}

getDateStringMomentByTzonlydate(timestamp: any): string {
  if (timestamp < 0)
  timestamp = timestamp * -1;
const localTimeZone =  moment(timestamp).tz(this.userSevice.getCookie('defaultTz'));
return localTimeZone.format('DD-MM-YYYY');
  // if(timestamp){
  //     if (timestamp < 0)
  //         timestamp = timestamp * -1;
  //     const localTimeZone =  moment(timestamp).tz(this.userSevice1Child.getCookie('defaultTz'));
  //     return localTimeZone.format('DD-MM-YYYY | HH:mm');
  // }

}

getRowBackgroundColor(element): string {
  // if (element.deleted == true) {
  //     return "lightgrey";
  // } else
  return "#FFFFFF";
}

getRowFontColor(element) {
  if (element.deleted == true) {
      return "italic";
  } else return "bold";
}

getFontColor(element) {
  if (element.deleted == true) {
      return "#808080";
  } else return "#000000";
}
getCrossWord(element) {
  if (element.deleted == true) {
      return "line-through";
  } else return "overline";
}

getDateStringMomentByTz(timestamp: any): string {
  if(timestamp){
      if (timestamp < 0)
          timestamp = timestamp * -1;
      const localTimeZone =  moment(timestamp).tz(this.userSevice.getCookie('defaultTz'));
      return localTimeZone.format('DD-MM-YYYY | HH:mm');
  }

}

// ngOnDestroy(): void {
//   WaterlineTestComponent.isComeBack = true
//     console.log('ngOnDestroy length' + this.dataN.length)
//     this.subscription.unsubscribe();
//     WaterlineTestComponent.oldData = this.dataSource.data
//     WaterlineTestComponent.cursor = this.paginationService.getCursor()
// }

hasMore = () => {
    return !this.dataSource || this.dataSource.data.length < this.limit;
}

nextPage() {
    this.isLoading = true;
    this.isNextPressed = true;
    this.paginationService.more();
    console.log("progress bar=",this.perPageData,this.dataCount,this.isLoading,this.isMoreDataAvailable)
}

isDataAvailable() {
    if (this.dataSource && this.dataSource.data.length > 0) {
        return true;
    }
    return false;
}

isMoreDataAvailableFun() {
    if (this.isMoreDataAvailable && this.dataSource.data.length % this.limit === 0) {
        return true;
    }
    return false;
}

viewReminderDetail(reminder_id) {
    this.router.navigate(['/remindersDetail'], { queryParams: { reminder_id: reminder_id } });
  
  }

openDialogWithRefstatus(ref: TemplateRef<any>, reminder_id,task_desc,task_name) {
    console.log('User Remark:', this.userRemark);
  console.log("calling status completed",reminder_id,task_desc)
  this.taskDesc = task_desc
  this.reminderId = reminder_id
  this.taskName = task_name
    this.dialog.open(ref, { width: '400px',height:'350px', disableClose: true });
  }

}


