// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


// for qa1
export const environment = {
    production: false,
    hmr       : false,
    firebaseConfig: {
        apiKey: 'AIzaSyDLmfVHgTRX6bD1EfCsJG4-gb7UI-kQqP8',
        authDomain: 'sterilwize-qa.firebaseapp.com',
        databaseURL: 'https://sterilwize-qa.firebaseio.com',
        projectId: 'sterilwize-qa',
        cloudFunctionURL: 'https://northamerica-northeast1-sterilwize-qa.cloudfunctions.net/',
        websiteUrl: 'https://sterilwize-qa.firebaseapp.com/',
        websiteFront:'https://wize.ai/',
        storageBucket: 'sterilwize-qa.appspot.com',
        messagingSenderId: '43034146014',
        appId: 'sterilwize-qa',
    }
};
