

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { StorageService } from 'app/main/module/common/service/storage.service';

@Injectable({
     providedIn: 'root' })

export class ReminderslistService {
    private itemsSubject: BehaviorSubject<any[]>;
    public itemsObservable: Observable<any[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;
    latestEntry: any;
    startingEntry: any;
    ROWS = 10;
    currentPage = 0;

    protected table = 'load';
    protected orderBy = 'startTimeNum';
    protected isOrderByNegative = true;
  mess: string;

    constructor(private afs: AngularFirestore, private userService: StorageService) {
    }


    getFieldValue(fieldName: string): Observable<any> {
      const docPath = "lab/" + this.userService.getCookie("lab") ;
      const docRef = this.afs.doc(docPath);
      return docRef.valueChanges().pipe(
        map((doc: any) => {
          if (doc && doc[fieldName]) {
            return doc[fieldName];
          } else {
            return null;
          }
        })
      );
    }

    getreminderHistoryById(barcode): Observable<any[]> {
      const patientCollectionPath = "lab/" + this.userService.getCookie("lab") + "/reminderHistory";
      return this.afs.collection(patientCollectionPath, ref => ref.where('id', '==', barcode)).snapshotChanges()
          .pipe(map(actions => {
              return actions.map((a: any) => {
                  return a.payload.doc.data();
              });
          }));
    }

   
      updateReminderHistory(docId: any,updateData:any): Promise<void> {
        console.log("docId",docId,updateData)
        const collectionPath = 'lab/' + this.userService.getCookie('lab') + '/reminderHistory';
    if(docId){
        const docRef = this.afs.collection(collectionPath).doc(docId);
        return docRef.update(updateData);
    } else {
      console.error('Invalid docId:', docId);
    }  
  }

  
      getAllReminderFromHistory() {
        const memberCollectionPath = 'lab/' + this.userService.getCookie('lab') + '/reminderHistory';;
        return this.afs.collection(memberCollectionPath).get();
      }
 

      formatTimestamp(timestamp: number, timezone: string): string {
        const date = new Date(timestamp);
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false, // Use 24-hour format
          timeZone: timezone,
        };
    
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        return formattedDate;
      }

  // moveDocument(docId: string) {
  //   console.log("docId",docId)
  //   const sourceCollection = 'lab/' + this.userService.getCookie('lab') + '/reminders';
  //   const destinationCollection = 'lab/' + this.userService.getCookie('lab') + '/reminderHistory';
  //   this.afs
  //     .doc(`${sourceCollection}/${docId}`)
  //     .get()
  //     .subscribe((doc) => {
  //       if (doc.exists) {
  //         const data = doc.data();
  //         this.afs.doc(`${destinationCollection}/${docId}`).set(data);
  //         this.afs.doc(`${sourceCollection}/${docId}`).delete();
  //       }
  //     });
  // }

  moveDocument(docId: string,newData: any) {
    console.log("docId",docId)
    const sourceCollection = 'lab/' + this.userService.getCookie('lab') + '/reminders';
    const destinationCollection = 'lab/' + this.userService.getCookie('lab') + '/reminderHistory';
    this.afs.doc<any>(`${sourceCollection}/${docId}`).valueChanges().subscribe((data) => {
      if (data) {
        const updatedData = {
          ...data, // Copy the existing data
          ...newData, // Update specific fields
          updated_on: new Date().getTime(), // Example: Update timestamp
          updated_by: this.userService.getCookie('name'), // Example: Update user
          status: 'Pending', // Example: Update status
        };
        
        // Step 3: Add the Document to the New Collection
        this.afs.collection(destinationCollection).doc(docId).set(updatedData)
          .then(() => {
            console.log('Document moved and updated successfully.');
          })
          .catch((error) => {
            console.error('Error moving document:', error);
          });
        
        // Step 4: Delete the Document from the Source Collection
        this.afs.doc(`${sourceCollection}/${docId}`).delete()
          .then(() => {
            console.log('Document deleted from the source collection.');
          })
          .catch((error) => {
            console.error('Error deleting document:', error);
          });
      }
    });
  }

  moveDocumentandUpdate(docId: string,newData: any) {
    console.log("docId",docId)
    const sourceCollection = 'lab/' + this.userService.getCookie('lab') + '/reminders';
    const destinationCollection = 'lab/' + this.userService.getCookie('lab') + '/reminderHistory';
    this.afs.doc<any>(`${sourceCollection}/${docId}`).valueChanges().subscribe((data) => {
      if (data) {
        const updatedData = {
          ...data, // Copy the existing data
          ...newData, // Update specific fields
          updated_on: new Date().getTime(), // Example: Update timestamp
          updated_by: this.userService.getCookie('name'), // Example: Update user
          status: 'Pending', // Example: Update status
        };
        
        // Step 3: Add the Document to the New Collection
        this.afs.collection(destinationCollection).doc(docId).set(updatedData)
          .then(() => {
            console.log('Document moved and updated successfully.');
            this.mess = "Success";
            return this.mess ;
          })
          .catch((error) => {
            console.error('Error moving document:', error);
            this.mess =  "Failed";
            return this.mess ;
          });
        
        // Step 4: update the Document from the Source Collection

        // this.updateReminderViewbyDocId(docId,nextsheduleData)
        
    //     this.afs.doc(`${sourceCollection}/${docId}`).set(nextsheduleData)
    //       .then((data) => {

    //         console.log('Document Updated for source collection.',data);
    //       })
    //       .catch((error) => {
    //         console.error('Error updating document:', error);
    //       });
       }
     });
     return this.mess;
  }

  updateReminderViewbyDocId(docId: any,updateData:any): Promise<void> {
    console.log("docId",docId,updateData)
    const collectionPath = 'lab/' + this.userService.getCookie('lab') + '/reminders';
if(docId){
    const docRef = this.afs.collection(collectionPath).doc(docId);
    return docRef.update(updateData);
} else {
  console.error('Invalid docId:', docId);
}  
}

  listenForRemindersChanges(): Observable<any[]> {
    const collectionPath = 'lab/' + this.userService.getCookie('lab') + '/reminderHistory'
    return this.afs.collection(collectionPath).valueChanges();
  }

  }