import { Injectable } from '@angular/core';
import { FuseSidebarComponent } from './sidebar.component';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FuseSidebarService {
    // Private
    private _registry: { [key: string]: FuseSidebarComponent } = {};

    /**
     * Constructor
     */
    constructor(private userService:StorageService,private afs: AngularFirestore,) {

    }

    /**
     * Add the sidebar to the registry
     *
     * @param key
     * @param sidebar
     */
    register(key, sidebar): void {
        // Check if the key already being used
        if (this._registry[key]) {

            return;
        }

        // Add to the registry
        this._registry[key] = sidebar;
    }

    /**
     * Remove the sidebar from the registry
     *
     * @param key
     */
    unregister(key): void {
        // Check if the sidebar exists
        if (!this._registry[key]) {

            // do something
        }

        // Unregister the sidebar
        delete this._registry[key];
    }


    getAllReminderFromHistory()  : Observable<any[]> {
        const memberCollectionPath = 'lab/' + this.userService.getCookie('lab') + '/reminderHistory';;
       
            return this.afs.collection(memberCollectionPath).valueChanges();
      }

    /**
     * Return the sidebar with the given key
     *
     * @param key
     * @returns {FuseSidebarComponent}
     */
    getSidebar(key): FuseSidebarComponent {
        // Check if the sidebar exists
        if (!this._registry[key]) {
            // do something

            return;
        }

        // Return the sidebar
        return this._registry[key];
    }
}
