import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {Observable, Subject} from 'rxjs';
import {StorageService} from '../../../common/service/storage.service';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {StatusService} from '../../status/status.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfigService} from '../../../common/config/config.service';
import {Globals} from '../../../common/global';
import {Cassette} from '../../cassette/cassette';
import * as moment from 'moment';
import {BaseComponent} from '../../../base/base.component';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {PatientdetailService} from "./patientdetail.service";
const timeZone = require('moment-timezone');


@Component({
    selector: 'app-patientdetail',
    templateUrl: './patientdetail.component.html',
    styleUrls: ['./patientdetail.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class PatientdetailComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    page = 0;
    dataLength = 0;
    perPageData = this.globals.perPageData;
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns.map(c => c.columnDef);
    dataSource = new MatTableDataSource<any>();

    @ViewChild('filter', { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    cassette: string;
    searchText: string;

    // Private
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<any[]>;

    protected extraCta = {
        visibility: false,
        text: '',
    };
    isEnterPressed = false;
    isAssignedPressed = false;
    isLoading = true;
    hasFirstDataShown = false;
    token = '';
    queryParam = {};
    urlPatientId = '';
    firstName = '';
    lastName = '';
    fullName = '';
    enableInfoMsg = false;
    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;


    constructor(
        private userSevice: StorageService, private fb: FormBuilder,
        private route: ActivatedRoute, private router: Router,
        private paginationService: PatientdetailService,
        private changeDetectorRefs: ChangeDetectorRef,
        private statusService: StatusService,
        public snackBar: MatSnackBar,
        private configService: ConfigService,
        private globals: Globals) {
        super(configService, globals);
        // super();
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.page = 0;

        this.token = this.route.snapshot.queryParams.token;
        if (this.token) {
            const keyVal = this.globals.decodeToa(this.token).split('##');
            const abKey = keyVal.map(para1 => para1.split('#')[0]);
            const abVal = keyVal.map(para1 => para1.split('#')[1]);
            this.queryParam = abVal.reduce((result, field, index) => {
                result[abKey[index]] = field;
                return result;
            }, {});
        }
        this.urlPatientId = this.queryParam['patient'];
        this.firstName = this.queryParam['firstName'] ? this.queryParam['firstName'] : '';
        this.lastName = this.queryParam['lastName'] ? this.queryParam['lastName'] : '';

        this.fullName = this.urlPatientId + ' ' + this.firstName + ' ' + this.lastName;
        this.paginationService.patientId = this.urlPatientId;
    }

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key.includes('Enter')
            && event.code.includes('Enter')
            && document.activeElement.id.includes('cassette')) {
            if (this.isAssignedPressed) {
                return;
            }
            this.isEnterPressed = true;
            if (this.searchText && this.searchText.length > 0) {
                this.onClickSearch();
            }
        } else {
            this.isEnterPressed = false;
        }
    }


    onClickSearch() {
        // alert('Search Text' + this.searchText);
        this.paginationService.searchedText = this.searchText;
        this.paginationService.search();
    }


    ngAfterViewInitViaBase(): void {
        this.page = 0;
        this.paginationService.setTable(this.getTableName(), this.getOrderByColumn());

        if (!this.isOrderByNegative()) {
            this.paginationService.setPositiveOrderBy();
        }
        this.paginationService.first();

        this.paginationService.pageObservable.subscribe(pageNumber => {
            this.page = pageNumber;
            this.changeDetectorRefs.detectChanges();
        });


        this.itemsObservable = this.paginationService.itemsObservable;
        this.itemsObservable.subscribe(data => {
            // this.datas = data;
            this.dataLength = data.length;
            if (data && data.length > 0) {

                data.forEach(value => this.updateRow(value));
                this.dataSource.data = data;
                this.hasFirstDataShown = true;
            } else {
                if (this.hasFirstDataShown) {
                    this.openSnackBar('No data available', 'OK');
                }
                this.hasFirstDataShown = false;
                this.enableInfoMsg = false;
                setTimeout(()=>{
                    this.hasFirstDataShown = true;
                    this.enableInfoMsg = true;
                },5000)



                // this.openSnackBar('No data available', 'OK');
            }
            this.isLoading = false;
            // this.filterDataByType(data);
        });

    }

    nextPage(): void {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.paginationService.next();
    }


    prevPage(): void {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.paginationService.prev();
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.page = 0;
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    isOrderByNegative(): boolean {
        return true;
    }

    getRowBackgroundColor(element): string {
        return '#FFFFFF';
    }

    canAssign(cassette): boolean {
        if (this.statusService.canAssign(cassette)) {
            return cassette.barcode;
        }
    }

    getColumn(): any[] {
        return [
            { columnDef: 'Sr', header: 'Sr.', cell: (element: Cassette, index: number) => `${this.page * 10 + index + 1}` },
            { columnDef: 'Barcode', header: 'Barcode', cell: (element: Cassette, index: number) => `${element.barcode}` },
            { columnDef: 'Type', header: 'Type', cell: (element: Cassette, index: number) => `${element.type}` },
            { columnDef: 'Load No', header: 'Load No', cell: (element: Cassette, index: number) => `${element.loadNo}` },
            { columnDef: 'Cycle No', header: 'Cycle No', cell: (element: Cassette, index: number) => `${element.cycleNo}` },
            {
                columnDef: 'Result', header: 'Result',
                cell: (element: Cassette, index: number) => `${this.getCassetteResult(element)}`,
                textColor: (element: Cassette, index: number) => `${this.getLoadColor(element)}`
            },
            { columnDef: 'Date', header: 'Date', cell: (element: Cassette, index: number) => `${element.date}` },
            { columnDef: 'Time', header: 'Time', cell: (element: Cassette, index: number) => `${element.time}` },
            {
                columnDef: 'BD', header: 'BD',
                cell: (element: Cassette, index: number) => `${element.barcodeBd ? element.barcodeBd : ''}`,
                textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.statusBd)}`
            },
            {
                columnDef: 'BI', header: 'BI',
                cell: (element: Cassette, index: number) => `${element.barcodeBi ? element.barcodeBi : ''}`,
                textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.statusBi)}`
            },
            {
                columnDef: 'EI', header: 'EI',
                cell: (element: Cassette, index: number) => `${element.barcodeEi ? element.barcodeEi : ''}`,
                textColor: (element: Cassette, index: number) => `${this.getSingleLoadColor(element.statusEi)}`
            },
            {
                columnDef: 'Status', header: 'Status',
                cell: (element: Cassette, index: number) => `${this.getLoadStatus(element)}`
            },
            // {columnDef: 'Details', header: 'Details',
            //     cell: (element: Cassette , index: number) => this.loadStatusUsed(this.getLoadStatus(element),element.id)},
            // {columnDef: 'Action', header: 'Action',
            //     cell: (element: Cassette , index: number) => this.canAssign(element)},

        ];
    }

    loadStatusAvailable(functValue, barcode) {
        if (functValue === 'Available') {
            return barcode;
        }
    }
    loadStatusUsed(functValue, id) {
        // if(functValue === 'USED'){
        return id;
        // }
    }

    getLoadStatus(load: Cassette): string {
        return this.statusService.getCassetteStatus(load);
    }

    getCassetteResult(load: Cassette): string {
        return this.statusService.getMessage(this.statusService.getStatusNew(load.statusBd, load.statusBi, load.statusEi));
    }

    getLoadColor(load: Cassette): string {
        return this.statusService.getColor(this.statusService.getStatusNew(load.statusBd, load.statusBi, load.statusEi));
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(this.statusService.getStatus(load));
        } else {
            return '#111111';
        }
    }
    // updateRow(row): any {
    //     row.date = moment(Math.abs(row.dateTimeNum)).format('DD-MM-YYYY');
    //     row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    // }
    updateRow(row): any {
        row.date = timeZone(Math.abs(row.dateTimeNum)).tz(this.userSevice.getCookie('defaultTz')).format('DD-MM-YYYY');
        row.time = timeZone(Math.abs(row.dateTimeNum)).tz(this.userSevice.getCookie('defaultTz')).format('HH:mm');
    }

    getOrderByColumn(): string {
        return 'dateTimeNum';
    }

    getTableName(): string {
        return 'cassette';
    }

    getTitleMatIcon(): string {
        return 'accessibility';
    }

    getTitle(): string {
        return 'Patient History';
    }

    getSearchText(): string {
        return 'Search cassette via barcode';
    }

    assignCassette(barcode) {
        this.router.navigate(['/assign'], { queryParams: { barcode: barcode.trim() } });
    }
    viewCassette(barcode) {
        this.router.navigate(['/viewcassettedetails'], { queryParams: { barcode: barcode } });

    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

}
